import { useState } from 'react';
import Dialog from '@mui/material/Dialog';

import { MuiButton } from '../button';
import { MuiTypography } from '../typography/typography';
import { logout } from '../../utils/fetch-request';

const text = {
  title: 'آیا می خواهید از حساب کاربری خارج شوید؟',
  cancel: 'بیخیال',
  exit: 'خروج',
};

export const LogoutDialog = ({ open, setOpen }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <div className='dialog-content'>
        <MuiTypography value='h7'>{text.title}</MuiTypography>

        <div className='dialog-action'>
          <MuiButton
            onClick={() => setOpen(false)}
            className='cancel-btn'
            disabled={isLoading}
            fullWidth
          >
            {text.cancel}
          </MuiButton>

          <MuiButton
            onClick={() => logout(setIsLoading)}
            variant='outlined'
            className='logout-btn'
            id='logout-btn'
            loading={isLoading}
            fullWidth
          >
            {text.exit}
          </MuiButton>
        </div>
      </div>
    </Dialog>
  );
};
