import { Dialog } from '@mui/material';

import { EqualVote } from './equal-vote';
import { MostVote } from './most-vote';

export const AlertDialog = ({
  open,
  setOpen,
  id,
  isEqual,
  equalVote,
  mostVoteObj,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      {isEqual ? (
        <EqualVote id={id} equalVote={equalVote} setOpen={setOpen} />
      ) : (
        <MostVote id={id} mostVoteObj={mostVoteObj} setOpen={setOpen} />
      )}
    </Dialog>
  );
};
