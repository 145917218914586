import React from 'react';

import { RectangleSkeleton } from '../../../../components/skeleton/skeleton';

export const MeetingDetailsSkeleton = () => {
  return (
    <div className='details'>
      <RectangleSkeleton className='mt-dtl-sklt-top' />
      <RectangleSkeleton className='mt-dtl-sklt-middle' />
      <RectangleSkeleton className='mt-dtl-sklt-bottom' />
    </div>
  );
};
