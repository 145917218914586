import { useEffect, useState } from 'react';
import {
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';

import './index.scss';
import VoteItem from './vote-item';
import { isArray } from '../../../../utils/utility';

export const VotingList = ({ ws, places }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    ws &&
      ws.addEventListener('message', ({ data }) => {
        const message = JSON.parse(data).message;

        if (message && message.vote && message.vote.already_voted_location_id) {
          setValue(message.vote.already_voted_location_id);
        }
      });
  }, [ws]);

  const item = isArray(places)
    ? places.map((place, i) => (
        <FormControlLabel
          className='vote-item-container'
          key={i}
          value={place.id}
          control={<Radio className='vote-item-radio' />}
          label={<VoteItem place={place} />}
        />
      ))
    : null;

  const handleRadioButtonClick = (id) => {
    setValue(id);
    const json = {
      action: 'poll',
      payload: {
        location_id: id,
      },
    };

    ws.send(JSON.stringify(json));
  };

  return (
    <div className='vote-list-container'>
      <FormControl className='form-control'>
        <RadioGroup
          value={value}
          className='vote-list-radio-group'
          onChange={(e) => handleRadioButtonClick(e.target.value)}
        >
          <Stack
            className='vote-list'
            divider={<Divider className='divider' flexItem />}
          >
            {item}
          </Stack>
        </RadioGroup>
      </FormControl>
    </div>
  );
};
