import React from 'react';

import '../styles/readyToLogin.scss';
import { MuiTypography } from '../../../components/typography/typography';
import { text } from '../../../texts/main-page';
import { MuiButton } from '../../../components/button';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const ReadyToLogin = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  return (
    <div className='ready-to-login'>
      <MuiTypography value={isSmallScreen ? 'h6' : 'h4'}>{text.readyToLogin}</MuiTypography>

      <div className='links-containers'>
        <MuiButton
          variant='contained'
          fullWidth
          onClick={() => navigate('login')}
          className='login'
        >
          {text.login}
        </MuiButton>
        <MuiButton fullWidth className='btn-video'>
          {text.video}
        </MuiButton>
      </div>
    </div>
  );
};

export default ReadyToLogin;
