import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import './index.scss';
import { ReactComponent as BackIcon } from '../../../assets/icon/back.svg';
import { Map } from '../components/map';
import { Details } from '../components/details';
import { HomeLayout } from '../../../components/layouts/home.layout';
import ErrorPage from '../../../components/error/error-page';
import { MeetingsContext } from '../../../services/meetings/meetings.context';
import { AuthContext } from '../../../services/authentication/auth.context';
import { fetchUsersData, isObject } from '../../../utils/utility';
import { urlConfig } from '../../../utils/env';
import { removeUser, request } from '../../../utils/fetch-request';
import { MeetingDetailsSkeleton } from '../components/details/meeting-details-skeleton';

export const MeetingDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { state } = useLocation();

  const { fetch } = useContext(AuthContext);
  const { setUsersInfo } = useContext(MeetingsContext);

  const [meeting, setMeeting] = useState('');
  const [isLoading, setIsLoading] = useState(!!meeting);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    id && getMeetingById(id, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, state]);

  const getMeetingById = (id, state) => {
    if (state && isObject(state.data)) {
      setMeeting(state.data);
    } else {
      getMeeting(id);
    }
  };

  const getMeeting = async (id) => {
    setIsLoading(true);

    const response = await request(`${urlConfig.meeting_details}${id}/`, 'get');

    try {
      setIsLoading(false);
      if (isObject(response.data)) {
        setMeeting(response.data);
        let uids = [];

        response.data.members.forEach(
          (i) => i.member_uuid && uids.push(i.member_uuid)
        );
        uids.length > 0 &&
          fetchUsersData('users_uuids', uids, setUsersInfo, fetch);
      }
    } catch (e) {
      setIsLoading(false);

      if (e.response && e.response.status === 404) {
        setNotFound(true);
      }

      removeUser(e);
    }
  };

  const Container = ({ children }) => (
    <div className='meeting-details-container'>
      {state && state.showBottomAppBar ? (
        <HomeLayout isHome={false}>
          <div className='meeting-details'>{children}</div>
        </HomeLayout>
      ) : (
        <div className='meeting-details'>{children}</div>
      )}
    </div>
  );

  return notFound ? (
    <ErrorPage />
  ) : (
    <Container>
      <BackIcon
        cursor={'pointer'}
        id='back'
        className='meeting-detail-back'
        onClick={() => navigate('/meetings', { replace: true })}
      />

      <Map />
      {isLoading ? <MeetingDetailsSkeleton /> : <Details meeting={meeting} />}
    </Container>
  );
};
