import { Avatar } from '@mui/material';

import useImageLoaded from '../../../../hook/useImageLoaded';
import { CircleSkeleton } from '../../../../components/skeleton/skeleton';

const CardAvatar = ({ user }) => {
  const { isLoading } = useImageLoaded(user);

  const UserAvatar = () => {
    if (isLoading) {
      return <CircleSkeleton className='card-users-avatar' />;
    } else if (user.avatar) {
      return <Avatar src={user.avatar} sx={{ width: 24, height: 24 }} />;
    } else {
      return <Avatar sx={{ width: 24, height: 24 }} />;
    }
  };

  return <UserAvatar />;
};

export default CardAvatar;
