import { ReactComponent as CameraIcon } from '../../../assets/icon/sidebar/camera.svg';
import UserAvatar from '../../../components/user-avatar';

export const ProfileImage = ({ user, profilePhoto, setProfilePhoto }) => {
  const handleFileInputChange = (e) => {
    const fileTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    const file = e.target.files[0];

    if (file && fileTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();

        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 800;
          const scaleSize = MAX_WIDTH / img.width;
          canvas.width = img.width * scaleSize;
          canvas.height = img.height * scaleSize;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(
            (blob) => {
              setProfilePhoto({
                url: URL.createObjectURL(file),
                blob,
              });
            },
            'image/jpeg',
            0.1
          );
        };
      };
    }
  };

  return (
    <label htmlFor='profile_image' className='profile-image'>
      <input
        type='file'
        accept='image/*'
        hidden
        id='profile_image'
        onChange={handleFileInputChange}
        name='image'
        required
      />

      <UserAvatar src={profilePhoto.url} user={user} className='profileImage' />

      <CameraIcon className='camera-icon' />
    </label>
  );
};
