import { useContext } from 'react';
import { Container } from '@mui/material';

import { AuthContext } from '../../../services/authentication/auth.context';
import { Login } from '../components/Login';
import { Verification } from '../components/Verification';

export const Index = () => {
	const { isEnterMobilePhoneByUser } = useContext(AuthContext);

	return <Container>{!isEnterMobilePhoneByUser ? <Login /> : <Verification />}</Container>;
};
