import { ReactComponent as People } from '../../../../assets/icon/voting/people.svg';
import { MuiTypography } from '../../../../components/typography/typography';

const VoteItem = ({ place }) => {
  return (
    <div className='vote-item'>
      <div>
        <MuiTypography value='h7' className='item-title'>
          {place.name}
        </MuiTypography>

        <MuiTypography
          value='bodySm'
          sx={{
            color: '#858585',
          }}
        >
          {place.city}
        </MuiTypography>
      </div>
      <div className='item-vote-number'>
        <People />
        <span className='vote-number'>{place.vote}</span>
      </div>
    </div>
  );
};

export default VoteItem;
