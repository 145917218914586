import { fetchUsersData } from '../../utils/utility';

// Notification of invitation to meeting or end of
// meeting when the user logs in for the first time
export const getInvitedMeetingNotification = (
  notifications,
  setInvitationNotification,
  setCompletedNotification,
  setNotificationsId,
  setUserIds
) => {
  let invitationArr = [];
  let completedArr = [];
  let ids = [];
  let userIds = [];

  notifications.forEach((item) => {
    const {
      sender__username,
      receiver__username,
      meeting__pk,
      status,
      typeofnotification,
      pk,
      meeting_date,
      meeting_title,
      reciever_status,
    } = JSON.parse(item);
    !userIds.includes(sender__username) && userIds.push(sender__username);

    !ids.includes(pk) && ids.push(pk);

    if (typeofnotification === 0) {
      if (reciever_status === 3) {
        invitationArr.push({
          receiver_status: reciever_status,
          sender_id: sender__username,
          receiver_id: receiver__username,
          title: meeting_title,
          meeting_id: meeting__pk,
          notification_id: pk,
          date: meeting_date,
          status,
          type: typeofnotification,
        });
      }
    }
    if (typeofnotification === 1) {
      if (reciever_status === 0 || reciever_status === 1) {
        completedArr.push({
          receiver_status: reciever_status,
          sender_id: sender__username,
          receiver_id: receiver__username,
          title: meeting_title,
          meeting_id: meeting__pk,
          notification_id: pk,
          date: meeting_date,
          status,
          type: typeofnotification,
        });
      }
    } else {
    }

    setUserIds((prevUserIds) => {
      const mergedSet = new Set([...prevUserIds, ...userIds]);
      return Array.from(mergedSet);
    });
  });

  setInvitationNotification(invitationArr);
  setCompletedNotification(completedArr);
  setNotificationsId(ids);
};

// Notification of invitation to meeting or
// end of meeting when the user is online
export const getCreatedMeetingNotification = (
  notification,
  setNotifications,
  setCompletedNotification,
  setUsersInfo,
  setNotificationsId,
  fetch,
  userIds,
  setUserIds
) => {
  const {
    action,
    meeting_id,
    sender_id,
    typeofnotification,
    reciever_id,
    meeting_title,
    meeting_date,
  } = JSON.parse(notification);

  setUserIds((prevUserIds) => {
    const numberSet = new Set(prevUserIds);
    numberSet.add(sender_id);
    return Array.from(numberSet);
  });

  setNotificationsId((prevNotification) => [...prevNotification, meeting_id]);

  fetchUsersData('users_ids', [...userIds, sender_id], setUsersInfo, fetch);

  if (action === 'create') {
    setNotifications((prevNotification) => [
      ...prevNotification,
      {
        // receiver_status: 3,
        action,
        meeting_id: meeting_id,
        sender_id,
        title: meeting_title,
        date: meeting_date,
        type: typeofnotification,
        receiver_id: reciever_id,
        status: 0,
      },
    ]);
  } else {
    setCompletedNotification((prevNotification) => [
      ...prevNotification,
      {
        // receiver_status: 3,
        action,
        meeting_id: meeting_id,
        sender_id,
        title: meeting_title,
        date: meeting_date,
        type: typeofnotification,
        receiver_id: reciever_id,
        status: 0,
      },
    ]);
  }
};
