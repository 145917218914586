import { useState, useContext } from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import { CreateMeetingContext } from '../../../../services/create-meeting/create-meeting.context';
import { LocationsContext } from '../../../../services/create-meeting/locations.context';
import { Box } from '@mui/system';
import { MuiInput } from '../../../../components/input';
import { MuiTypography } from '../../../../components/typography/typography';

const cities = require('../../../../locations-data/cities.json');

const text = {
  whatCity: 'چه شهری؟',
  city: 'شهر',
};

export const Cities = ({ cityState, setCityState }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { setSelectedLocations } = useContext(CreateMeetingContext);
  const { setCity } = useContext(LocationsContext);

  const addCity = (item) => {
    setSelectedLocations([]);
    setCityState(item);
    setCity(item);
  };

  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue);
  };

  return (
    <Box mb={4}>
      <MuiTypography value='h7'>{text.whatCity}</MuiTypography>

      <Autocomplete
        onInputChange={(event, newInputValue) => {
          handleInputChange(event, newInputValue);
        }}
        onChange={(event, newValue) => {
          addCity(newValue);
        }}
        className='text-field-auto-complete'
        options={cities}
        getOptionLabel={(option) => option.name}
        value={cityState}
        inputValue={searchTerm}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        renderInput={(params) => <MuiInput {...params} />}
        size='small'
        placeholder={text.city}
        disablePortal
        disableClearable
      />
    </Box>
  );
};
