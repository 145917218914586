import { useContext, useState } from 'react';
import { DialogActions } from '@mui/material';

import { MuiButton } from '../../../../components/button';
import { CreateMeetingContext } from '../../../../services/create-meeting/create-meeting.context';
import { MuiTypography } from '../../../../components/typography/typography';

const text = {
  ok: 'بله',
  cancel: 'فعلا نه',
};

export const MostVote = ({ mostVoteObj, id, setOpen }) => {
  const { endMeeting } = useContext(CreateMeetingContext);

  const [isLoading, setIsLoading] = useState(false);

  const title = mostVoteObj
    ? `${mostVoteObj.name} بیشترین رای را آورده، بریم دورهمی؟`
    : `هنوز به هیچ مکانی رای داده نشده، از پایان رای گیری مطمئنی؟`;
  return (
    <div className='dialog-set-meeting'>
      <MuiTypography value='h7'>{title}</MuiTypography>

      <DialogActions className='dialog-action'>
        <MuiButton
          onClick={() => setOpen(false)}
          disabled={isLoading}
          variant='outlined'
          fullWidth
        >
          {text.cancel}
        </MuiButton>

        <MuiButton
          onClick={() =>
            endMeeting(id, mostVoteObj ? mostVoteObj.id : null, setIsLoading)
          }
          loading={isLoading}
          variant='contained'
          fullWidth
        >
          {text.ok}
        </MuiButton>
      </DialogActions>
    </div>
  );
};
