import { useContext, useMemo } from 'react';
import { Grid } from '@mui/material';

import Card from '../components/card/card';
import { EmptyState } from './empty-state';
import { isArray } from '../../../utils/utility';
import InvitationMeeting from '../../notifications/components/invitation-meeting';
import { MeetingsContext } from '../../../services/meetings/meetings.context';
import { NotificationsContext } from '../../../services/notification/notifications.context';
import NotificationContainer from '../../../components/notifications/container';
import { MuiTypography } from '../../../components/typography/typography';
import { CardsSkeleton } from '../../../components/skeleton/skeleton';

const text = {
  inProgress: 'در حال برگزاری',
};

export const MeetingsContainer = ({ isMemories }) => {
  const { meetings, memories, isLoading } = useContext(MeetingsContext);
  const { homeNotification } = useContext(NotificationsContext);

  const data = useMemo(
    () => (!isMemories ? meetings : memories),
    [isMemories, meetings, memories]
  );

  const Completed = ({ meeting }) =>
    meeting.location.length > 0 ? (
      <MuiTypography value='bodySm' sx={{ marginTop: '8px' }} noWrap>
        {meeting.location[0].address}
      </MuiTypography>
    ) : null;

  const Inprogress = (
    <MuiTypography value='bodySm' sx={{ color: '#F55D21', marginTop: '8px' }}>
      {text.inProgress}
    </MuiTypography>
  );

  const MeetingsCard = () => {
    return (
      isArray(data) && (
        <div className='meetings-list'>
          <Grid className='meetings-grid' container spacing={2}>
            {data.map((meeting, i) => (
              <Grid item xs={6} key={`${i}-${meeting.id}`}>
                {meeting.status === 0 ? (
                  <Card
                    meeting={meeting}
                    meetingStatus={<Completed meeting={meeting} />}
                  />
                ) : (
                  <Card meeting={meeting} meetingStatus={Inprogress} />
                )}
              </Grid>
            ))}
          </Grid>
        </div>
      )
    );
  };

  const Notifications = () => (
    <NotificationContainer isHome={true}>
      {homeNotification.map(
        (notification, i) =>
          notification.status === 0 && (
            <InvitationMeeting
              key={i}
              notification={notification}
              showMinimizeButton={true}
            />
          )
      )}
    </NotificationContainer>
  );

  const isNotificationExist = homeNotification.filter(
    (notification) => notification.status === 0
  );

  const MeetingsComponent = () => {
    if (isLoading) {
      return <CardsSkeleton />;
    }

    if (homeNotification.length > 0) {
      if (isNotificationExist) {
        return <Notifications />;
      } else {
        return <MeetingsCardOrEmptyState />;
      }
    } else {
      return <MeetingsCardOrEmptyState />;
    }
  };

  const MeetingsCardOrEmptyState = () => {
    return isArray(data) ? (
      <MeetingsCard />
    ) : (
      <EmptyState status={isMemories} />
    );
  };

  return <MeetingsComponent />;
};
