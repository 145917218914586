import { useContext } from 'react';
import { Dialog } from '@mui/material';

import { MeetingsContext } from '../../../../services/meetings/meetings.context';
import { reject } from '../../../../texts/voting';
import { MuiTypography } from '../../../../components/typography/typography';
import { MuiButton } from '../../../../components/button';

const RejectMeetingDialog = ({
  id,
  meeting,
  isLoading,
  setIsLoading,
  open,
  setOpen,
}) => {
  const { acceptRejectMeeting } = useContext(MeetingsContext);
  const { title, cancel, yes } = reject;

  let backToHome = true;

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <div className='dialog-content'>
        <MuiTypography value='h7'>{title}</MuiTypography>

        <div className='dialog-action'>
          <MuiButton
            onClick={() => setOpen(false)}
            className='cancel-btn'
            disabled={isLoading}
            fullWidth
          >
            {cancel}
          </MuiButton>

          <MuiButton
            onClick={() =>
              acceptRejectMeeting(
                id,
                'reject',
                meeting,
                backToHome,
                setIsLoading
              )
            }
            variant='outlined'
            className='logout-btn'
            id='logout-btn'
            loading={isLoading}
            fullWidth
          >
            {yes}
          </MuiButton>
        </div>
      </div>
    </Dialog>
  );
};

export default RejectMeetingDialog;
