import { useState, createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { urlConfig } from '../../utils/env';
import { isArray } from '../../utils/utility';
import { filterMeetings, getMeetingUsersData } from './meetings.services';
import { AuthContext } from '../authentication/auth.context';
import { removeCookies, removeUser, request } from '../../utils/fetch-request';

export const MeetingsContext = createContext();

export const MeetingsContextProvider = ({ children }) => {
  const navigate = useNavigate('');

  const { fetch } = useContext(AuthContext);

  const [meetings, setMeetings] = useState([]);
  const [memories, setMemories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [invitationMeetingList, setInvitationMeetingList] = useState([]);
  const [usersInfo, setUsersInfo] = useState([]);
  const [uids, setUids] = useState([]);

  const updateMeetings = (user) => {
    getMeetings(user);
    getMemories(user);
  };

  const getMeetings = async (user) => {
    try {
      const response = await request(urlConfig.meeting_landing, 'get');
      getMeetingUsersData(response.data, uids, setUids, setUsersInfo, fetch);
      setMeetings(filterMeetings(response.data, user));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      removeUser(e);
    }
  };

  const getMemories = async (user) => {
    try {
      const response = await request(urlConfig.meeting_memory, 'get');
      getMeetingUsersData(response.data, uids, setUids, setUsersInfo, fetch);
      setMemories(filterMeetings(response.data, user));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      removeUser(e);
    }
  };

  const changeMeetingsStatus = (id, status, meeting) => {
    let invitationMeetingArr = invitationMeetingList.filter(
      (meeting) => meeting.id !== id
    );

    if (status === 'accept') {
      navigate(`/vote/${id}`, {
        state: { meeting: meeting },
      });
      setInvitationMeetingList(invitationMeetingArr);
    } else if (status === 'reject') {
      setInvitationMeetingList(invitationMeetingArr);
    }
  };

  const acceptRejectMeeting = (
    id,
    status,
    meeting,
    backToHome,
    setIsLoading
  ) => {
    setIsLoading(true);
    request(`${urlConfig.meeting_details}${id}/${status}/`, 'post')
      .then((res) => {
        changeMeetingsStatus(id, status, meeting);
        backToHome && navigate('/meetings');
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        removeUser(e);
      });
  };

  const getUserByUid = (members, usersInfo) => {
    let uids = [];
    let users = [];

    isArray(members) &&
      members.forEach((member) => {
        member.member_uuid && uids.push(member.member_uuid);
      });

    uids.length > 0 &&
      uids.forEach((uid) =>
        users.push(usersInfo.find((user) => user.uid === uid))
      );
    return users;
  };

  useEffect(() => {
    if (!Cookies.get('_mzb_token')) {
      removeCookies();
    }
  }, []);

  return (
    <MeetingsContext.Provider
      value={{
        meetings,
        memories,
        invitationMeetingList,
        usersInfo,
        isLoading,
        setMeetings,
        updateMeetings,
        acceptRejectMeeting,
        getUserByUid,
        setUsersInfo,
      }}
    >
      {children}
    </MeetingsContext.Provider>
  );
};
