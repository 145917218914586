import { createTheme } from '@mui/material';
import { faIR } from '@mui/material/locale';
import { sizesVariant } from './sizes'

export const muiTheme = createTheme(
  {
    direction: 'rtl',
    palette: {
      primary: {
        main: '#F55D21',
        contrastText: '#fdfdfd',
      },
      secondary: {
        main: '#F5214F',
      },
      background: {
        default: '#FAFAFA',
        paper: '#fdfdfd',
      },
      error: {
        main: '#D32F2F',
      },
      info: {
        main: '#1D87E9',
      },
      button: {
        primary: '#FDFDFD',
        tertiary: '#060b2f',
        disabled: '#9C9C9C',
      },
      success: {
        main: '#388E3C',
      },
      gray: {
        gray1: '#EEEEEE',
        gray2: '#BDBDBD',
        gray3: '#858585',
        gray4: '#E4E4E4',
        gray5: '#696969',
      },
      yellow: {
        main: '#F5C721',
      },
      darkBlue: {
        main: '#1B283A',
      },
      text: {
        primary: '#121212',
        secondary: '#757575',
        tertiary: '#060b2f',
        disabled: '#9C9C9C',
        inverse: '#fdfdfd',
        error: '#B00020',
        success: '#138000',
      },
      size: sizesVariant
    },
    ui: {
      primary: '#F55D21',
      secondary: '#F5214F',
      tertiary: '#F5C721',
      white: '#FDFDFD',
      disabled: '#DEDEDE',
      error: '#D32F2F',
      success: '#388E3C',
    },
    bg: {
      primary: '#FAFAFA',
    },

    typography: {
      fontFamily: 'IRANSansX',
    },
  },

  faIR
);
