import { useContext, useEffect, useState } from 'react';
import { styled, Box } from '@mui/material';

import { UserInfo } from '../components/drawer/user-info';
import { MeetingTab } from '../components/meetingTabs/meeting-tab';
import { Drawer } from '../../../components/drawer/drawer';
import { AuthContext } from '../../../services/authentication/auth.context';
import { MeetingsContext } from '../../../services/meetings/meetings.context';

const MeetingsContainer = styled(Box)({
  height: 'calc(100% - (80px - 16px))',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const Meetings = () => {
  const [open, setOpen] = useState(false);

  const { isAuthenticated, user } = useContext(AuthContext);
  const { updateMeetings } = useContext(MeetingsContext);

  useEffect(() => {
    isAuthenticated && user && updateMeetings(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <MeetingsContainer>
      <UserInfo handleDrawerOpen={handleDrawerOpen} />
      <MeetingTab />
      <Drawer open={open} handleDrawerClose={handleDrawerClose} />
    </MeetingsContainer>
  );
};
