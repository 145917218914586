import { useContext, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { MuiButton } from '../../../../components/button';
import { CreateMeetingContext } from '../../../../services/create-meeting/create-meeting.context';
import { ReactComponent as CloseIcon } from '../../../../assets/icon/close-circle.svg';
import { MuiTypography } from '../../../../components/typography/typography';

const text = {
  confirm: 'تایید',
};

export const EqualVote = ({ equalVote, id, setOpen }) => {
  const { endMeeting } = useContext(CreateMeetingContext);

  const [selected, setSelected] = useState(equalVote[0].id);
  const [isLoading, setIsLoading] = useState(false);

  const title = `${equalVote[0].name} و ${equalVote[1].name} به یک اندازه رای آوردن، انتخاب شما
      کدام است؟`;

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const Options = equalVote.map((place) => (
    <FormControlLabel
      key={place.id}
      value={place.id}
      control={<Radio />}
      label={<MuiTypography value='h7'>{place.name}</MuiTypography>}
    />
  ));

  return (
    <div className='dialog-set-meeting'>
      <CloseIcon onClick={() => setOpen(false)} className='close-icon' />

      <MuiTypography value='h7' sx={{ marginRight: '32px' }}>
        {title}
      </MuiTypography>

      <FormControl className='dialog-radio-group'>
        <RadioGroup onChange={handleChange} value={selected}>
          {Options}
        </RadioGroup>
      </FormControl>

      <MuiButton
        onClick={() => endMeeting(id, selected, setIsLoading)}
        loading={isLoading}
        className='confirm-btn'
        variant='outlined'
      >
        {text.confirm}
      </MuiButton>
    </div>
  );
};
