import { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';

import { urlConfig } from '../../../../utils/env';
import { AuthContext } from '../../../../services/authentication/auth.context';
import {
  MuiTypography,
  ResendCode,
} from '../../../../components/typography/typography';

const text = {
  resendCode: 'دریافت مجدد کد',
};

export const VerificationTimer = () => {
  const Ref = useRef(null);

  const [timer, setTimer] = useState('1:59');
  const [disabled, setDisabled] = useState(true);

  const { mobilePhone } = useContext(AuthContext);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(minutes + ':' + (seconds > 9 ? seconds : '0' + seconds));
    } else if (total <= 0) {
      setDisabled(false);
    }
  };

  const clearTimer = (e) => {
    setDisabled(true);
    setTimer('1:59');

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setMinutes(deadline.getMinutes() + 1);
    deadline.setSeconds(deadline.getSeconds() + 59);
    return deadline;
  };

  const resendCode = () => {
    !disabled &&
      axios
        .post(urlConfig.login, {
          phonenumber: `+98${mobilePhone}`,
        })
        .then((res) => {
          clearTimer(getDeadTime());
        })
        .catch(console.log);
  };

  useEffect(() => {
    clearTimer(getDeadTime());
    // eslint-disable-next-line
  }, []);

  return (
    <Box className='verification-timer' display='flex' component='span'>
      <ResendCode
        className={disabled ? 'resend-code-disabled' : 'resend-code'}
        disabled={disabled}
        onClick={resendCode}
      >
        {text.resendCode}
        {disabled ? ':' : ''}
      </ResendCode>

      {disabled && <MuiTypography value='captionMd'>{timer}</MuiTypography>}
    </Box>
  );
};
