import { useContext, useState } from 'react';
import moment from 'moment';
import AdapterJalali from '@date-io/date-fns-jalali';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { DialogActions } from '@mui/material';
import { parseISO } from 'date-fns';

import { CreateMeetingContext } from '../../../../services/create-meeting/create-meeting.context';
import { MuiInput } from '../../../../components/input';
import { MuiButton } from '../../../../components/button';

const text = {
  meetingDate: 'تاریخ دورهمی',
  confirm: 'تایید',
};

export const DatePicker = () => {
  const { meeting, setMeeting } = useContext(CreateMeetingContext);

  const [value, setValue] = useState(meeting.date);

  const changeDate = (newValue) => {
    setValue(parseISO(moment(newValue).format('YYYY-MM-DDT00:00')));

    let newMeeting = {
      ...meeting,
      date: moment(newValue).format('YYYY-MM-DDT23:59'),
    };
    setMeeting(newMeeting);
  };

  const weekDays = {
    ش: 'ش',
    '1ش': 'ی',
    '2ش': 'د',
    '3ش': 'س',
    '4ش': 'چ',
    '5ش': 'پ',
    ج: 'ج',
  };

  const CustomActionBar = (props) => {
    return (
      <DialogActions>
        <MuiButton onClick={(event) => props.onAccept()}>
          {text.confirm}
        </MuiButton>
      </DialogActions>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterJalali}>
      <MobileDatePicker
        minDate={new Date()}
        value={value}
        onChange={changeDate}
        dayOfWeekFormatter={(day) => weekDays[day]}
        renderInput={(params) => (
          <MuiInput
            {...params}
            size='small'
            label={text.meetingDate}
            fullWidth
            className='input-date-picker'
          />
        )}
        showToolbar={false}
        sx={{
          width: 300,
        }}
        components={{
          ActionBar: CustomActionBar,
        }}
        componentsProps={{
          actionBar: {
            actions: ['accept'],
          },
        }}
      />
    </LocalizationProvider>
  );
};
