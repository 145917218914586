import { useState, useEffect } from 'react';

const useImageLoaded = (user) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const image = new Image();
    image.src = user && user.avatar;

    const handleImageLoad = () => {
      setIsLoading(false);
    };

    image.addEventListener('load', handleImageLoad);

    if (user && !user.avatar) {
      handleImageLoad();
    }

    return () => {
      image.removeEventListener('load', handleImageLoad);
    };
  }, [user, setIsLoading]);

  return { isLoading };
};

export default useImageLoaded;
