import { Skeleton } from '@mui/material';

import './styles.scss';
import { theme } from '../../theme/index';

export const ProfileAvatarSkeleton = ({ className }) => (
  <Skeleton
    variant='circular'
    width={theme.sizesVariant[5]}
    height={theme.sizesVariant[5]}
    className={className}
  />
);

export const TextSkeleton = ({ className }) => (
  <Skeleton variant='text' className={className} />
);

export const CircleSkeleton = ({ className }) => (
  <Skeleton variant='circular' className={className} />
);

export const RectangleSkeleton = ({ className }) => (
  <Skeleton variant='rounded' className={className} />
);

export const CardsSkeleton = () => (
  <div className='skeleton-container'>
    {Array.from({ length: 4 }, (_, i) => (
      <Skeleton variant='rounded' key={i} />
    ))}
  </div>
);
