import { RectangleSkeleton } from '../../../../components/skeleton/skeleton';

const ChatRoomSkeleton = () => {
  return (
    <div className='chat-room-skeleton'>
      <RectangleSkeleton className='chat-room-button' />
      {/* <RectangleSkeleton className='chat-room-text' /> */}
    </div>
  );
};

export default ChatRoomSkeleton;
