import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { isArray } from '../../utils/utility';
import { urlConfig } from '../../utils/env';
import { removeUser, request } from '../../utils/fetch-request';
import { MeetingsContext } from '../meetings/meetings.context';
import { NotificationsContext } from '../notification/notifications.context';
import {
  handleValidation,
  removeSpecialCharacter,
} from '../../global-functions/global-functions';

export const CreateMeetingContext = createContext();

export const CreateMeetingContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { usersInfo } = useContext(MeetingsContext);
  const { ws } = useContext(NotificationsContext);

  const [meeting, setMeeting] = useState({
    title: '',
    date: null,
    contacts: [], //phone number and username
    nonMembers: [],
    invited_users: [], //uuid
    users_ids: [], //id
    locations: [],
  });

  const [selectedLocations, setSelectedLocations] = useState(meeting.locations);
  const [isLoading, setIsLoading] = useState(false);
  const [isExist, setIsExist] = useState(false); //if the contact was added
  const [openDialog, setOpenDialog] = useState(false); //dialog for add contact
  const [tooManyRequestError, setTooManyRequestError] = useState(false);
  const [limitNumberOfInvitations, setLimitNumberOfInvitations] = useState(false);
  const [invitationLimit, setInvitationLimit] = useState(false);

  useEffect(() => {
    !meeting.contacts.length > 0 && setIsExist(false);
  }, [meeting.contacts.length]);

  useEffect(() => {
    if (meeting.nonMembers.length > 10) {
      setInvitationLimit(true);
    } else {
      setInvitationLimit(false);
    }
  }, [meeting.nonMembers]);

  const addLocation = () => {
    let newMeeting = {};
    newMeeting = { ...meeting, locations: selectedLocations };

    setMeeting(newMeeting);
  };

  const meetingData = (meeting) => {
    return {
      title: meeting.title,
      date_time: meeting.date,
      location: meeting.locations,
      invited_users: meeting.invited_users.map((i) => i.uid),
      none_members_phonenumbers: meeting.nonMembers,
      users_ids: meeting.users_ids,
    };
  };

  // Send the meeting id to the websocket
  // to notify users that the meeting has been created
  const sendMeetingId = (meeting_id, action) => {
    ws.send(
      JSON.stringify({
        action,
        meeting_id,
      })
    );
  };

  const createMeeting = () => {
    if (meeting.title && meeting.date) {
      setIsLoading(true);
      request(urlConfig.meeting_details, 'post', meetingData(meeting))
        .then((res) => {
          if (res.data) {
            sendMeetingId(res.data.id, 'create');
            navigate('/vote/' + res.data.id, {
              state: {
                meeting: res.data,
                showAlert: true,
              },
            });
            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          removeUser(e);
          if (e.response) {
            if (e.response.status === 429) {
              setTooManyRequestError(true);
            } else if (e.response.status === 413) {
              setLimitNumberOfInvitations(true);
            }
          }
        });
    } else {
    }
  };

  const updateContacts = (phoneNumber, username, uid) => {
    let uids = meeting.invited_users;
    let phoneNumbers = [];

    if (uid) {
      if (!uids.includes(uid)) {
        uids.push(uid);
      }
    } else {
      if (!meeting.nonMembers.includes(phoneNumber)) {
        phoneNumbers.push(phoneNumber);
      }
    }

    if (!meeting.nonMembers.includes(phoneNumber)) {
      let meetingArr = {
        ...meeting,
        contacts: [
          ...meeting.contacts,
          { phoneNumber: phoneNumber, username: username || null },
        ],
        // invited_users: uids,
        nonMembers: [...meeting.nonMembers, ...phoneNumbers],
      };

      setMeeting(meetingArr);
    }
  };

  const openContactPicker = async (text, isSupport) => {
    const options = {
      props: ['name', 'tel'],
      opts: { multiple: true },
    };

    if (isSupport) {
      const checker = await navigator.contacts.getProperties();

      if (checker.includes('name', 'tel')) {
        const contacts = await navigator.contacts.select(
          options.props,
          options.opts
        );

        if (!contacts.length) {
          return;
        }

        if (isArray(contacts)) {
          let newNonMember = [];
          let newContacts = [];

          contacts.forEach((contact) => {
            if (contact.tel.length > 0) {
              contact.tel.forEach((phone) => {
                if (handleValidation(removeSpecialCharacter(phone))) {
                  if (
                    !newNonMember.includes(
                      `+98${removeSpecialCharacter(phone)}`
                    )
                  ) {
                    newNonMember.push(`+98${removeSpecialCharacter(phone)}`);
                  }
                  setIsExist(true);
                }
              });
            }
          });

          newNonMember.map((i) =>
            newContacts.push({
              phoneNumber: i,
              username: null,
            })
          );

          setMeeting({
            ...meeting,
            nonMembers: newNonMember,
            contacts: newContacts,
          });
        }
      }
    } else {
      alert(text.isBrowserSupport);
    }
  };

  const endMeeting = async (meetingId, locationId, setIsLoading) => {
    setIsLoading(true);

    if (locationId) {
      ws.send(
        JSON.stringify({
          action: 'poll',
          payload: {
            location_id: locationId,
          },
        })
      );
    }

    sendMeetingId(meetingId, 'set');

    const data = {
      id: locationId ? locationId : null,
    };

    try {
      let res = await request(
        `${urlConfig.meeting_details}${meetingId}/done/`,
        'post',
        data
      );

      navigate(`/meetings/details/${meetingId}`, {
        state: {
          data: res.data,
          usersInfo: usersInfo,
          showBottomAppBar: true,
        },
      });

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <CreateMeetingContext.Provider
      value={{
        meeting,
        selectedLocations,
        isLoading,
        isExist,
        openDialog,
        tooManyRequestError,
        limitNumberOfInvitations,
        invitationLimit,
        setLimitNumberOfInvitations,
        setIsExist,
        setOpenDialog,
        setSelectedLocations,
        setMeeting,
        addLocation,
        updateContacts,
        createMeeting,
        openContactPicker,
        setIsLoading,
        endMeeting,
      }}
    >
      {children}
    </CreateMeetingContext.Provider>
  );
};
