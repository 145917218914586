const ACCOUNT_BASE_URL = process.env.REACT_APP_ACCOUNT_BASE_URL
  ? process.env.REACT_APP_ACCOUNT_BASE_URL
  // : 'http://localhost:8000';
: "https://account.mizban.io";
// : 'https://account-alpha.mizbans.com';

const EVENT_BASE_URL = process.env.REACT_APP_EVENT_BASE_URL
  ? process.env.REACT_APP_EVENT_BASE_URL
  // : 'http://localhost:8001';
: "https://event.mizban.io";
// : 'https://event-alpha.mizbans.com';

const EVENT_BASE_URL_WITHOUT_HTTPS = process.env
  .REACT_APP_EVENT_BASE_URL_WITHOUT_HTTPS
  ? process.env.REACT_APP_EVENT_BASE_URL_WITHOUT_HTTPS
  // : 'ws://localhost:8001';
: "wss://event.mizban.io";
// : 'wss://event-alpha.mizbans.com';

const urlConfig = {
  ACCOUNT_BASE_URL,
  EVENT_BASE_URL,
  mapApiKey:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImFiMDBlZGFjNmZiZTY2NTYwM2VhNmMzZGY4NTg0YzkzYWVjOGNlNWE5OWY4Mzk2ODdhMDNkODY4YjNlMTY2YWNjOTM4NDBhODc5YzE0NmEzIn0.eyJhdWQiOiIyMDk4NCIsImp0aSI6ImFiMDBlZGFjNmZiZTY2NTYwM2VhNmMzZGY4NTg0YzkzYWVjOGNlNWE5OWY4Mzk2ODdhMDNkODY4YjNlMTY2YWNjOTM4NDBhODc5YzE0NmEzIiwiaWF0IjoxNjc1Njk5NDA5LCJuYmYiOjE2NzU2OTk0MDksImV4cCI6MTY3ODIwNTAwOSwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.l02UdbYsMCXI2aT8HU9GY2PxQV3xn8OJJYD8rHRFeyQ-72aKkBO7AH4MCSod5Waf0zOFGtWE02NqiiwOYlVOLZwaSPiChnwFjjFeh3S2DjDEkx_pyDjlA48Hl7vBDxwxh8VQcSvdTMJZTaiADojEhFRvKBRwGXH8PJAvxVo4A9qPiObMSatyU0rpI09O78hwnkeNRjMmmMIpkBv10k1yYQ8bAsAFsbxOj5uIzgailNTKECY2T2Lji03f__-_RCR0ECntoxiH3R2wYlvGUFkLkTveNucs6sF_ouHNYFLJC1Vy04NdKaSqGZ0jwr3775d1-xLjl6PpY-GZWzUxEVJTFA',
  authorization: 'lpPuCBu8.QKbCshFSoZYH3uxHsjRcR5MHwagBTNgh',

  refresh: ACCOUNT_BASE_URL + '/api/token/refresh/',
  register: ACCOUNT_BASE_URL + '/api/register/',
  // login: ACCOUNT_BASE_URL + '/api/meta/send/otp',
  // send_otp: ACCOUNT_BASE_URL + '/api/meta/code/login',
  // logout: ACCOUNT_BASE_URL + '/api/logout/',
  login: ACCOUNT_BASE_URL + '/api/meta/verify/mobile',
  code_verify: ACCOUNT_BASE_URL + '/api/login/',

  user: ACCOUNT_BASE_URL + '/api/user/',
  get_users_data: ACCOUNT_BASE_URL + '/api/meta/usersinfo',
  friends: ACCOUNT_BASE_URL + '/api/meta/get/friends',
  poll: ACCOUNT_BASE_URL + '/poll',
  get_number_of_votes: EVENT_BASE_URL + '/poll/get/votes',
  meeting_details: EVENT_BASE_URL + '/api/meetings/',
  meeting_landing: EVENT_BASE_URL + '/api/meetings/?memory=0',
  meeting_memory: EVENT_BASE_URL + '/api/meetings/?memory=1',
  chat_history: EVENT_BASE_URL + '/chat/get',
  event_ws_vote: EVENT_BASE_URL_WITHOUT_HTTPS + '/poll',
  event_ws_chat: EVENT_BASE_URL_WITHOUT_HTTPS + '/msg',
  event_ws_notification: EVENT_BASE_URL_WITHOUT_HTTPS + '/notify',
};

const _urlConfig = urlConfig;
export { _urlConfig as urlConfig };
