import { Box } from '@mui/material';

import { text } from '../../../../texts/main-page';
import { MuiTypography } from '../../../../components/typography/typography';
import '../../styles/mizban-target/mizban-target-md-screen.scss';
import '../../styles/mizban-target/mizban-target-lg-screen.scss';
import Detail from './detail';

const MizbanTraget = () => {
  return (
    <Box className='mizban-target-container'>
      <Box className='background' />

      <Box className='mizban-target-detail'>
        <MuiTypography value='h4'>
          {text.mizbanTargetAudienceTitle}
        </MuiTypography>

        <Box className='mizban-target-box'>
          {Object.values(text.mizbanTargetAudienceDetail).map(
            ({ title, description }, i) => (
              <Detail title={title} description={description} key={i} />
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MizbanTraget;
