import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, InputAdornment } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import { AuthContext } from '../../../services/authentication/auth.context';
import { ReactComponent as BackIcon } from '../../../assets/icon/back.svg';
import { isArray } from '../../../utils/utility';
import { Friend } from '../components/friend';
import { MuiInput } from '../../../components/input';
import { MuiTypography } from '../../../components/typography/typography';

const text = {
  search: 'جستجو ...',
  friendList: 'لیست دوستان',
};

export const FriendsList = () => {
  const navigate = useNavigate();
  const { friends } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');

  const searchList =
    isArray(friends) &&
    friends
      .filter((friend) => {
        let value = searchTerm && searchTerm.toLowerCase();
        let isExist = friend.username.includes(value);
        let result = '';

        if (value) {
          if (isExist) {
            result = value;
          }
        } else {
          result = friend;
        }

        return result;
      })
      .slice(0, 8)
      .map((item, i) => <Friend key={i} friend={item} />);

  return (
    <div>
      <Box mb={4}>
        <BackIcon
          cursor={'pointer'}
          id='back'
          onClick={() => navigate('/meetings', { replace: true })}
        />
      </Box>

      <Box mb={2}>
        <MuiTypography>{text.friendList}</MuiTypography>
      </Box>

      <MuiInput
        size='small'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={text.search}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton edge='end'>
                <SearchRoundedIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
      />

      {searchList}
    </div>
  );
};
