import './styles.scss';
import { MuiTypography } from '../typography/typography';

const text = {
  share: 'اشتراک‌ گذاری',
  more: 'جزئیات بیشتر',
};

export const MeetingsCard = ({
  handleClick,
  title,
  date_time,
  status,
  avatars,
}) => {
  return (
    <div className='meeting-card'>
      <div onClick={handleClick}>
        <div className='card-header'>
          <div className='card-title'>
            <MuiTypography value='bodySm' noWrap>
              {title}
            </MuiTypography>
            <MuiTypography value='bodyXs' noWrap className='meeting-card-date'>
              {date_time}
            </MuiTypography>
          </div>
        </div>
        <div className='card-content'>
          {status}
          {avatars}
        </div>
      </div>

      <div className='card-footer'>
        <div className='share-btn'>
          <MuiTypography value='bodyXs'>{text.share}</MuiTypography>
        </div>
        <div className='detail-btn' onClick={handleClick}>
          <MuiTypography value='bodyXs'>{text.more}</MuiTypography>
        </div>
      </div>
    </div>
  );
};
