import { useState, useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../../services/authentication/auth.context';
import { ProfileImage } from '../components/profile-image';
import { urlConfig } from '../../../utils/env';
import { MuiButton } from '../../../components/button';
import '../components/profile.scss';
import { ReactComponent as BackIcon } from '../../../assets/icon/back.svg';
import { MuiInput, MuiTextArea } from '../../../components/input';
import { removeUser } from '../../../utils/fetch-request';
import Alert from '../../../components/alert';
import { alertText } from '../../../texts/alert';
import { text } from '../../../texts/profile';

export const Profile = () => {
  const { user, setUser, fetch } = useContext(AuthContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [about, setAbout] = useState('');
  const [aboutCounter, setAboutCounter] = useState(0);
  const [usernameValidationError, setUsernameValidationError] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState({
    url: '',
    blob: '',
  });

  const changeUsername = (val) => {
    let value = val.replace(/\s/g, '');
    setUsername(value);
  };

  const changeAbout = (e) => {
    let value = e.target.value;
    setAbout(value);
    setAboutCounter(value.toString().length);
  };

  useEffect(() => {
    if (user) {
      setProfilePhoto({
        url: user.avatar,
        blob: '',
      });
      setUsername(user.username);
    }
  }, [user]);

  const sendRequest = (data) =>
    fetch(urlConfig.user, 'patch', data, setIsLoading)
      .then((e) => {
        setUser(e.data);
        navigate('/meetings');
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e.response.status);
        if (e.response.status === 400) {
          setUsernameValidationError(true);
        }
        removeUser(e);
      });

  const updateProfile = () => {
    setIsLoading(true);

    const formData = new FormData();

    profilePhoto.blob &&
      formData.append('avatar', profilePhoto.blob, 'photo.jpeg');
    formData.append('username', username);

    sendRequest(formData);
  };

  return (
    <>
      <Box>
        <BackIcon
          cursor={'pointer'}
          id='back'
          onClick={() => navigate('/meetings', { replace: true })}
        />
      </Box>

      {usernameValidationError ? (
        <Alert
          text={alertText.usernameError}
          id='error'
          setCloseAlert={setUsernameValidationError}
        />
      ) : null}

      <div className='profile-container'>
        <ProfileImage
          user={user}
          profilePhoto={profilePhoto}
          setProfilePhoto={setProfilePhoto}
        />

        <div className='username'>
          <MuiInput
            size='small'
            fullWidth
            label={text.username}
            value={username}
            inputProps={{ maxLength: 25 }}
            onChange={(e) => changeUsername(e.target.value)}
          />
        </div>

        <div className='about'>
          <MuiTextArea
            onChange={changeAbout}
            label={text.about}
            multiline
            rows={4}
            // value={about}
            inputProps={{ maxLength: 2600 }}
            defaultValue={about}
            disabled
            fullWidth
          />

          <label className='about-me-counter'>
            {aboutCounter}/{text.aboutCounter}
          </label>
        </div>

        <MuiButton
          onClick={updateProfile}
          fullWidth
          variant='contained'
          disabled={isLoading}
          loading={isLoading}
        >
          {text.save}
        </MuiButton>
      </div>
    </>
  );
};
