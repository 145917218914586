import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import './index.scss';
import { MeetingsContainer } from '../meetings-container';
import { MuiTypography } from '../../../../components/typography/typography';

export const MeetingTab = () => {
  const [current, setCurrent] = useState('active');

  return (
    <Box className='meeting-tabs'>
      <div className='tab-bar'>
        <div className={`switch ${current === 'active' ? 'right' : 'left'}`} />

        <div
          className={`tab ${current === 'active' ? 'current-tab' : ''}`}
          onClick={() => setCurrent('active')}
        >
          <MuiTypography
            value='bodyMd'
            sx={{
              lineHeight: '18.4px !important',
            }}
          >
            {text.active}
          </MuiTypography>
        </div>

        <div
          className={`tab ${current === 'memories' ? 'current-tab' : ''}`}
          onClick={() => setCurrent('memories')}
        >
          <MuiTypography value='bodyMd'>{text.memories}</MuiTypography>
        </div>
      </div>

      <div className='meeting-content'>
        {current === 'active' ? (
          <MeetingsContainer isMemories={false} />
        ) : (
          <MeetingsContainer isMemories={true} />
        )}
      </div>
    </Box>
  );
};

const text = {
  active: 'پیش رو',
  memories: 'خاطرات',
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
