import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { AuthContext } from '../../services/authentication/auth.context';

export const AuthLayout = () => {
  const { user, isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated && user && !user.username) {
    return <Navigate to='/register' replace />;
  }

  if (isAuthenticated) {
    return <Navigate to='/meetings' replace />;
  }

  return <Outlet />;
};
