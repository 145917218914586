import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import '../styles/navbar/navbar.scss';
import '../styles/navbar/navbar-md-screen.scss';
import { text } from '../../../texts/main-page';
import { MuiButton } from '../../../components/button';
import { ReactComponent as Menu } from '../../../assets/icon/menu.svg';
import DrawerAppBar from './drawer';

const Navbar = (props) => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  return (
    <>
      <AppBar position='sticky' className='main-header'>
        <nav className='nav'>
          <Box className='nav-content'>
            <Box className='nav-content-mobile'>
              <IconButton aria-label='open drawer' onClick={handleDrawerToggle}>
                <Menu />
              </IconButton>

              <Link to='/' className='mizban-icon'>
                <img
                  src={
                    process.env.PUBLIC_URL + '/icons/navbar-brand-mobile.svg'
                  }
                  alt='mizban'
                />
              </Link>
            </Box>

            <Box className='nav-item brand'>
              <Link to='/' className='mizban-icon'>
                <img
                  src={process.env.PUBLIC_URL + '/icons/navbar-brand.svg'}
                  alt='mizban'
                />
              </Link>
            </Box>

            <Box className='nav-item'>
              <MuiButton className='nav-link'>{text.aboutUs}</MuiButton>
              <MuiButton className='nav-link'>{text.support}</MuiButton>
              <MuiButton>{text.introduceInMizban}</MuiButton>

              <MuiButton
                onClick={() => navigate('login')}
                variant='contained'
                className='login-btn'
              >
                {text.login}
              </MuiButton>
            </Box>
          </Box>
        </nav>
      </AppBar>

      <DrawerAppBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
    </>
  );
};

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
