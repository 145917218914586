import { Box } from '@mui/material';
import '../styles/footer.scss';
import { MuiTypography } from '../../../components/typography/typography';
import { text } from '../../../texts/main-page';

const Footer = () => {
  return (
    <Box className='footer'>
      <Box className='contact'>
        <MuiTypography value='h6'>{text.aboutUs}</MuiTypography>
        <MuiTypography value='h6'>{text.contactUs}</MuiTypography>
      </Box>
      <Box className='contact'>
        <a href='https://instagram.com/mizban.ai?igshid=NTc4MTIwNjQ2YQ' rel="noreferrer" target='_blank'>
          <img
            src={process.env.PUBLIC_URL + '/icons/instagram.svg'}
            alt='linkedin'
          />
        </a>
        <a href='https://www.linkedin.com/company/mizban-ai' rel="noreferrer" target='_blank'>
          <img
            src={process.env.PUBLIC_URL + '/icons/linkedin.svg'}
            alt='instagram'
          />
        </a>
      </Box>
    </Box>
  );
};

export default Footer;
