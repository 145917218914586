import Alert from '../../../../components/alert';
import { alertText } from '../../../../texts/alert';

const text = {
  1: alertText.wrongCode,
  2: alertText.limitationInMinute,
  3: alertText.limitationInHour,
};

const ErrorComponent = ({ status, setStatus }) =>
  status ? (
    <Alert text={text[status]} id='error' setCloseAlert={setStatus} />
  ) : null;

export default ErrorComponent;
