import { useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import './index.scss';
import { MuiTypography } from '../../../../components/typography/typography';
import { MeetingsContext } from '../../../../services/meetings/meetings.context';
import { isArray } from '../../../../utils/utility';
import { useLocation } from 'react-router-dom';
import UsersAvatar from './users-avatar';

export const Users = ({ members }) => {
  const { usersInfo, getUserByUid } = useContext(MeetingsContext);
  const { state } = useLocation();
  const [users, setUsers] = useState([]);

  const { usersInfoState } = state || '';

  const getUsers = () => {
    if (usersInfoState) {
      setUsers(usersInfoState);
    } else if (usersInfo.length > 0) {
      setUsers(getUserByUid(members, usersInfo));
    }
  };

  useEffect(() => {
    getUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usersComponent =
    users && isArray(users)
      ? users.map((user, i) => (
          <div className='users' key={`${user.uid}-${i}`}>
            <UsersAvatar user={user} />
            <MuiTypography>{user.username}</MuiTypography>
          </div>
        ))
      : null;

  return (
    <Stack
      className='users-container'
      direction='row'
      spacing={2.5}
      style={{ overflow: 'auto' }}
    >
      {usersComponent}
    </Stack>
  );
};
