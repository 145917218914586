import { useEffect, useRef, useState } from 'react';
import { motion, useInView } from 'framer-motion';

import '../../styles/features/features.scss';
import '../../styles/features/features-md-screen.scss';
import '../../styles/features/features-lg-screen.scss';
import { AnimateButton } from './buttons';
import { text } from '../../../../texts/main-page';
import { MuiTypography } from '../../../../components/typography/typography';
import DetailContainer from './detail-container';
import { useMediaQuery } from '@mui/material';

const Features = () => {
  const isLargeScreen = useMediaQuery('(min-width: 1200px)');
  const ref = useRef(null);
  const isInView = useInView(ref);
  const swiperRef = useRef(null);
  const [isMoved, setIsMoved] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);

  const position = isLargeScreen
    ? [
        {
          x: 367,
          y: 133,
          text: text.friendlyGathering,
          className: 'friendlyGathering',
        },
        { x: 568, y: 213, text: text.guide, className: 'guide' },
        { x: 719, y: 293, text: text.events, className: 'events' },
      ]
    : [
        {
          x: 0,
          y: 0,
          text: text.friendlyGathering,
          className: 'friendlyGathering',
        },
        { x: 0, y: 0, text: text.guide, className: 'guide' },
        { x: 0, y: 0, text: text.events, className: 'events' },
      ];

  useEffect(() => {
    if (isInView === true) {
      setIsMoved(true);

      setTimeout(() => {
        setShowFeatures(true);
      }, 600);
    } else {
      setIsMoved(false);
      setShowFeatures(false);
    }
  }, [isInView]);

  return (
    <div className={isMoved ? 'features features-scrolled' : 'features'}>
      <div className='features-wrapper'>
        <div className='logo-container'>
          <MuiTypography value='h4'>{text.features}</MuiTypography>
        </div>

        <motion.div className='btns-container'>
          {position.map((item, index) => (
            <AnimateButton
              key={item.text}
              position={item}
              isMoved={isMoved}
              setIsMoved={setIsMoved}
              isActive={activeButtonIndex === index}
              swiperRef={swiperRef}
              index={index}
              setActiveButtonIndex={setActiveButtonIndex}
            />
          ))}
        </motion.div>
      </div>

      <div ref={ref} className='ref'  />

      <DetailContainer
        showFeatures={showFeatures}
        swiperRef={swiperRef}
        isMoved={isMoved}
        isLargeScreen={isLargeScreen}
      />
    </div>
  );
};

export default Features;
