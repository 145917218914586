import { useContext, useEffect, useState } from 'react';
import { Avatar, Stack } from '@mui/material';

import { CreateMeetingContext } from '../../../../../services/create-meeting/create-meeting.context';
import { isArray } from '../../../../../utils/utility';
import { MuiButton } from '../../../../../components/button';
import { MuiTypography } from '../../../../../components/typography/typography';

const text = {
  add: 'اضافه کردن',
  added: 'اضافه شده',
};

export const LocationItem = ({ location }) => {
  const [isAdded, setIsAdded] = useState(false);
  const { name, city } = location;
  const { selectedLocations, setSelectedLocations } =
    useContext(CreateMeetingContext);

  const isLocationExist = () =>
    selectedLocations.length > 0 &&
    selectedLocations.some(
      (i) =>
        i.name === location.name &&
        i.lat === location.lat &&
        i.lon === location.lon
    );

  useEffect(() => {
    setIsAdded(isLocationExist());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLocations = () => {
    setIsAdded(!isAdded);
    let removeLocations =
      isArray(selectedLocations) &&
      selectedLocations.filter(
        (item) =>
          item.name !== location.name &&
          item.lat !== location.lat &&
          item.lng !== location.lng
      );

    let addLocations = [...selectedLocations, location];

    if (isLocationExist()) {
      setSelectedLocations(removeLocations);
    } else {
      setSelectedLocations(addLocations);
    }
  };

  return (
    <>
      <Stack className='location-item'>
        <Stack direction='row' flexGrow={1} alignItems='center'>
          <Avatar className='suggestion-avatar' />
          <div>
            <MuiTypography
              value='bodyMd'
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 120,
                height: 22,
              }}
            >
              {name}
            </MuiTypography>
            <MuiTypography
              value='bodySm'
              sx={{
                color: '#858585',
                whiteSpace: 'nowrap',
              }}
            >
              {city}
            </MuiTypography>
          </div>
        </Stack>

        <MuiButton
          onClick={updateLocations}
          className='add-suggestion-friend-btn'
          variant={isAdded ? 'contained' : 'outlined'}
        >
          {isAdded ? text.added : text.add}
        </MuiButton>
      </Stack>
    </>
  );
};
