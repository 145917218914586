import { Avatar } from '@mui/material';

import { ProfileAvatarSkeleton } from './skeleton/skeleton';
import useImageLoaded from '../hook/useImageLoaded';

const UserAvatar = ({ user, className, src }) => {
  const { isLoading } = useImageLoaded(user);
  let avatar = src ?? (user?.avatar ?? null);

  const Component = () => {
    if (isLoading) {
      return <ProfileAvatarSkeleton className={className} />;
    } else if (avatar) {
      return <Avatar className={className} src={avatar} />;
    } else {
      return <Avatar className={className} />;
    }
  };

  return <Component />;
};

export default UserAvatar;
