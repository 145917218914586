import { createContext, useState } from 'react';

export const LocationsContext = createContext();

export const LocationsContextProvider = ({ children }) => {
  const [locations, setLocations] = useState([]);
  const [category, setCategory] = useState('cafe');
  const [isLoading, setIsLoading] = useState(false);
  const [city, setCity] = useState({
    id: 194,
    province: 17,
    name: 'شیراز',
    lat: 29.5917677,
    lng: 52.5836982,
  });

  return (
    <LocationsContext.Provider
      value={{
        city,
        setCity,
        locations,
        setLocations,
        category,
        setCategory,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};
