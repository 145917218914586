export const colors = {
  primary: '#F55D21',
  secondary: '#F5214F',
  background: '#FAFAFA',
  white: '#FDFDFD',
  error: '#D32F2F',
  info: '#1D87E9',
  success: '#388E3C',
  gray1: '#EEEEEE',
  gray2: '#BDBDBD',
  gray3: '#858585',
  gray4: '#E4E4E4',
  gray5: '#696969',
  yellow: '#F5C721',
  darkBlue: '#1B283A',
  text: '#121212',
};
