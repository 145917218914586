import { useContext, useState } from 'react';
import { Box } from '@mui/material';

import { Categories } from '../components/set-meeting/categories';
import { Cities } from '../components/set-meeting/cities';
import { MuiButton } from '../../../components/button';
import { LocationsContext } from '../../../services/create-meeting/locations.context';
import { ReactComponent as BackIcon } from '../../../assets/icon/back.svg';
import { getLocations } from '../../../services/create-meeting/locations.services';

const text = {
  search: 'جستجو',
};

export const SearchLocations = ({ setOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { city, category, setLocations } = useContext(LocationsContext);

  const [cityState, setCityState] = useState(null);
  const [categoryState, setCategoryState] = useState('');

  return (
    <div>
      <Box mb={4}>
        <BackIcon
          cursor={'pointer'}
          id='back'
          onClick={() => {
            setOpen(false);
          }}
        />
      </Box>

      <Cities cityState={cityState} setCityState={setCityState} />

      <Categories
        categoryState={categoryState}
        setCategoryState={setCategoryState}
      />

      <MuiButton
        onClick={() => {
          getLocations(city, category, setIsLoading, setLocations, setOpen);
        }}
        disabled={!cityState || !categoryState || isLoading}
        loading={isLoading}
        variant='contained'
        fullWidth
      >
        {text.search}
      </MuiButton>
    </div>
  );
};
