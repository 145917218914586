import { useContext, useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';

import { AuthContext } from '../../../../services/authentication/auth.context';
import { isArray } from '../../../../utils/utility';

export const Map = ({ location }) => {
  const { default_location } = useContext(AuthContext);
  const [center, setCenter] = useState('');
  const mapRef = useRef();

  useEffect(() => {
    if (isArray(location) && location[0].lat && location[0].lng) {
      setCenter({
        lat: location[0].lat,
        lng: location[0].lng,
      });
    }
  }, [location]);

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      map.setView(center);
    }
  }, [center]);

  return (
    <div className='details-map'>
      <MapContainer
        ref={mapRef}
        className='map-container'
        style={{ height: '100%' }}
        center={center ? center : default_location}
        zoom={15}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        {center && center.lat && center.lng ? (
          <Marker
            position={center}
            icon={
              new Icon({
                iconUrl: markerIconPng,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
              })
            }
          />
        ) : null}
      </MapContainer>
    </div>
  );
};
