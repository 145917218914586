import { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import './index.scss';
import { urlConfig } from '../../../../utils/env';
import { isObject } from '../../../../utils/utility';
import {
  userDataTransform,
  userNameValidation,
} from '../../../../services/authentication/auth.service';
import { AuthContext } from '../../../../services/authentication/auth.context';
import { MuiButton } from '../../../../components/button';
import { MuiInput } from '../../../../components/input';
import { MuiTypography } from '../../../../components/typography/typography';
import { useTheme } from '@mui/material';

const text = {
  enterUsername: 'نام کاربری خود را وارد کنید.',
  usernameLabel: 'نام کاربری',
  error: 'این نام کاربری قبلا استفاده شده.',
  rules:
    'نام کاربری باید حداقل شامل 4 کاراکتر که می تواند از حروف انگلیسی، اعداد و کاراکتر خاص (_) باشد.',
  btn: 'ورود',
  counter: 25,
};

export const Register = () => {
  const navigate = useNavigate();
  const { palette } = useTheme();

  const [username, setUsername] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user, setUser, fetch } = useContext(AuthContext);

  let token = Cookies.get('_mzb_token');

  // ? --------------------------- functions -------------------------

  const inputOnChange = (e) => {
    let value = e.target.value.replace(/\s/g, '');
    setUsername(value);
  };

  const handleKeyDown = (e) => {
    if (
      e.keyCode === 13 &&
      username.length > 0 &&
      userNameValidation(username)
    ) {
      updateUser();
    }
  };

  const updateUser = () => {
    setError(false);
    setIsLoading(true);

    if (username && userNameValidation(username)) {
      fetch(urlConfig.user, 'patch', {
        ...user,
        username,
      })
        .then((res) => {
          if (res.data && isObject(res.data)) {
            setUser(userDataTransform(res.data));
            navigate('/meetings');
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.log(e.response.data.username);
          if (
            e.response.status === 400 &&
            e.response.data.code &&
            e.response.data.code[0] === '409'
          ) {
            setError(true);
            setIsLoading(false);
          }
        });
    }
  };

  // ? ----------------------- useEffect --------------------

  useEffect(() => {
    if (token) {
    } else {
      navigate('/');
    }
  }, [navigate, token]);

  // --------------------------------------------------------

  return (
    <div className='register'>
      <MuiTypography value='bodyMd'>{text.enterUsername}</MuiTypography>
      <MuiInput
        className={username.length > 0 ? 'username' : 'username empty-input'}
        variant='outlined'
        size='small'
        value={username}
        label={text.usernameLabel}
        onChange={inputOnChange}
        onKeyDown={handleKeyDown}
        inputProps={{ maxLength: 25 }}
      />
      {error ? (
        <MuiTypography
          value='captionMd'
          id='username-error'
          sx={{
            color: palette.error.main,
          }}
        >
          {text.error}
        </MuiTypography>
      ) : null}

      <MuiTypography value='captionMd'>{text.rules}</MuiTypography>

      <MuiButton
        fullWidth
        variant='contained'
        onClick={updateUser}
        className={username.length === 0 ? 'button-disabled' : ''}
        disabled={username.length === 0 || !userNameValidation(username)}
        loading={isLoading}
      >
        {text.btn}
      </MuiButton>
    </div>
  );
};
