export const text = {
  aboutUs: 'درباره ما',
  contactUs: 'تماس با ما',
  introduceInMizban: 'در میزبان معرفی شوید',
  support: 'پشتیبانی',
  title: 'لذت با هم بودن',
  subtitle:
    'فعالیت هایی که دوست داری رو همراه کسانی که دوستشون داری، بیشتر و راحت تر تجربه کن.',
  login: 'ورود به وب اپلیکیشن',
  video: 'ویدیو آموزشی',
  features: 'امکانات میزبان',
  friendlyGathering: 'دورهمی های دوستانه',
  guide: 'همراه و راهنما',
  events: 'رویدادها و تخفیف ها',

  friendlyGatheringDetail: {
    heading:
      'قرارهاتون رو سریع تر و آسون تر هماهنگ کنید و بهترین انتخاب ها رو برای دورهمی تون داشته باشین',
    titles: {
      p1: 'هماهنگی قرار',
      p2: 'چت',
      p3: 'به اشتراک گذاری فایل و مدیا',
    },
  },
  guideDetail: {
    heading:
      'برای فعالیت هایی که دوست داری همراه مناسب پیدا کن یا اگه نیاز به راهنما داری بهترین هاش رو پیدا کن',
    titles: {
      p1: 'تور اختصاصی',
      p2: 'پکیج های گردشگری',
    },
  },
  eventsDetail: {
    heading:
      'رویدادهای مورد علاقت رو دنبال کن و هیچ رویدادی در اطرافت رو از دست نده.',
    titles: {
      p1: 'جست و جوی رویدادها',
      p2: 'خرید بلیط رویدادها',
      p3: ' تخفیف گروهی',
    },
  },

  mizbanTargetAudienceTitle: 'میزبان برای چه کسانی مناسب است؟',
  mizbanTargetAudienceDetail: {
    box1: {
      title: 'برای جمع های دوستانه',
      description:
        'از تخفیف های گروهی استفاده کنید، قرارهاتون رو راحت تر و سریع تر هماهنگ کنید و از رویدادهایی که اطرافتون هست باخبر بشید.',
    },
    box2: {
      title: 'برای مسافران و مهاجران',
      description:
        'با بهترین مکان های شهر آشنا شو، دوستان و همراهان جدید پیدا کن و اگه نیاز به راهنما داری، راهنمای خصوصی متناسب با روحیاتت استخدام کن.',
    },
    box3: {
      title: 'برای کسب و کارها',
      description:
        'کسب و کارتون رو معرفی کنید و با برگزاری رویدادهای جذاب مخاطب بیشتری جذب کنید.',
    },
    box4: {
      title: 'برای میزبان ها',
      description:
        'خودتون رو معرفی کنید و راهنمای بقیه در گردشگری شهری و یا سایر نیازهاشون بشید و درآمد کسب کنید.',
    },
  },

  readyToLogin: 'برای ورورد به میزبان آماده ای؟',
};
