import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import '../components/index.scss';
import { ReactComponent as BackIcon } from '../../../assets/icon/back.svg';
import { isArray } from '../../../utils/utility';
import { CompletedMeeting } from '../components/completed-meeting';
import { NotificationsContext } from '../../../services/notification/notifications.context';
import InvitationMeeting from '../components/invitation-meeting';
import NotificationContainer from '../../../components/notifications/container';
import { MuiTypography } from '../../../components/typography/typography';
import { NotificationSkeleton } from '../components/skeleton';

const text = {
  error: 'مجدد تلاش کنید',
};

export const Notifications = () => {
  const navigate = useNavigate();
  const {
    ws,
    completedNotification,
    invitationNotification,
    minimizeNotification,
  } = useContext(NotificationsContext);

  useEffect(() => {
    invitationNotification.length > 0 && minimizeNotification(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationNotification.length]);

  const completedList =
    isArray(completedNotification) &&
    completedNotification.map((notification, i) => (
      <CompletedMeeting key={i} notification={notification} />
    ));

  const invitationList =
    isArray(invitationNotification) &&
    invitationNotification.map((notification, i) => (
      <InvitationMeeting
        key={i}
        notification={notification}
        showMinimizeButton={false}
      />
    ));

  return (
    <div className='notifications'>
      <Box mb={4}>
        <BackIcon
          cursor={'pointer'}
          onClick={() => navigate('/meetings', { replace: true })}
        />
      </Box>

      {ws && ws.readyState === 3 ? (
        <MuiTypography value='bodySm'>{text.error}</MuiTypography>
      ) : null}
      {ws && ws.readyState === 0 ? <NotificationSkeleton /> : null}

      {invitationNotification.length > 0 || completedNotification.length > 0 ? (
        <NotificationContainer>
          {completedList}
          {invitationList}
        </NotificationContainer>
      ) : null}
      {/* <FriendshipInvitation /> */}
    </div>
  );
};
