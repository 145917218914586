import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { MuiTypography } from '../typography/typography';
import { MuiButton } from '../button';
import ForbiddenErrorImage from '../../assets/images/page-not-found.png';

const text = {
  forbiddenError: 'صفحه مورد نظر یافت نشد.',
  button: 'بازگشت به صفحه اصلی',
};

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className='error-message-box'>
      <Grid container columns={{ xs: 4 }} className='error-image-box'>
        <Grid item xs={2.5}>
          <img
            src={ForbiddenErrorImage}
            className='error-image'
            alt='forbidden error'
          />
        </Grid>
        <MuiTypography value='captionLg'>{text.empty}</MuiTypography>
      </Grid>
      <MuiTypography value='h7' className='error-text'>
        {text.forbiddenError}
      </MuiTypography>
      <MuiButton
        variant='contained'
        onClick={() => {
          navigate('/', { replace: true });
        }}
      >
        {text.button}
      </MuiButton>
    </div>
  );
};

export default PageNotFound;
