import { useContext } from 'react';

import './index.scss';
import { ReactComponent as DownArrow } from '../../../../assets/icon/arrow-down.svg';
import { AuthContext } from '../../../../services/authentication/auth.context';
import { MuiTypography } from '../../../../components/typography/typography';
import { TextSkeleton } from '../../../../components/skeleton/skeleton';
import UserAvatar from '../../../../components/user-avatar';

export const UserInfo = ({ handleDrawerOpen }) => {
  const { user } = useContext(AuthContext);

  const Username = () =>
    user ? (
      <>
        <MuiTypography value='h7'>{user.username}</MuiTypography>
        <DownArrow className='down-arrow' />
      </>
    ) : (
      <TextSkeleton className='username' />
    );

  return (
    <div className='user-avatar' id='user-avatar' onClick={handleDrawerOpen}>
      <UserAvatar user={user} className='avatar' />
      <Username />
    </div>
  );
};
