import { motion } from 'framer-motion';

import { MuiTypography } from '../../../../components/typography/typography';
import { useMediaQuery } from '@mui/material';

export const AnimateButton = ({
  position,
  isMoved,
  setIsMoved,
  isActive,
  swiperRef,
  index,
  setActiveButtonIndex,
}) => {
  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const className =
    isMoved && isActive
      ? `isActive ${position.className} isActive-${position.className}`
      : position.className;

  return (
    <motion.div
      animate={{
        x: isMoved ? position.x : 0,
        y: isMoved ? position.y : 0,
      }}
      transition={{ duration: 0.8 }}
    >
      <div
        onClick={() => {
          if (swiperRef && swiperRef.current) {
            setActiveButtonIndex(index);
            swiperRef.current.slideTo(index);
          }

          setIsMoved(true);
          window.scrollTo({
            top: 420,
            behavior: 'smooth',
          });
        }}
        className={className}
      >
        <MuiTypography value={isSmallScreen ? 'buttonSm' : 'buttonLg'}>{position.text}</MuiTypography>
      </div>
    </motion.div>
  );
};
