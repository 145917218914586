export const actions = {
  addPlace: 'اضافه کردن مکان',
  endOfVoting: 'پایان رأی‌ گیری',
  rejectMeeting: 'بی خیال من',
};

export const reject = {
  title: 'آیا می خواهید از این دورهمی خارج شوید؟',
  cancel: 'فعلا نه',
  yes: 'بله',
};
