import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import App from './App';

import './fonts/woff/IRANSansX-Black.woff';
import './fonts/woff/IRANSansX-Bold.woff';
import './fonts/woff/IRANSansX-DemiBold.woff';
import './fonts/woff/IRANSansX-ExtraBold.woff';
import './fonts/woff/IRANSansX-Light.woff';
import './fonts/woff/IRANSansX-Medium.woff';
import './fonts/woff/IRANSansX-Regular.woff';
import './fonts/woff/IRANSansX-Thin.woff';
import './fonts/woff/IRANSansX-UltraLight.woff';

const root = ReactDOM.createRoot(document.getElementById('root'));

document.dir = 'rtl';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// Disable reportWebVitals in production
if (process.env.NODE_ENV === 'development') {
  reportWebVitals();
}
