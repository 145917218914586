import './index.scss';
import close_box from '../../assets/icon/voting/close-square.svg';
import { MuiTypography } from '../typography/typography';

const Alert = ({ text, id, setCloseAlert }) => {
  return (
    <div className='alert' id={`alert-${id}`}>
      <div className='alert-content'>
        <MuiTypography value='alert' id={id}>{text}</MuiTypography>
      </div>
      <img
        className='close-box'
        src={close_box}
        alt='close'
        onClick={() => setCloseAlert(false)}
      />
    </div>
  );
};

export default Alert;
