import { Divider, Stack } from '@mui/material';

const NotificationContainer = ({ children, isHome }) => {
  return (
    <div className='notification-container'>
      <Stack
        className={isHome ? 'notification home-notification' : 'notification'}
        divider={<Divider className='divider' flexItem />}
      >
        {children}
      </Stack>
    </div>
  );
};

export default NotificationContainer;
