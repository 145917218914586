import { useContext, useEffect, useRef, useState } from 'react';
import { IconButton, InputBase } from '@mui/material';

import './index.scss';
import Message from './Message';
import { ReactComponent as Recorder } from '../../../../assets/icon/chat-room/microphone.svg';
import { ReactComponent as Attachment } from '../../../../assets/icon/chat-room/attachment.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/icon/chat-room/arrow-down-fill.svg';
import { ReactComponent as SendMessageIcon } from '../../../../assets/icon/send-message.svg';
import { isArray, isObject } from '../../../../utils/utility';
import { urlConfig } from '../../../../utils/env';
import { AuthContext } from '../../../../services/authentication/auth.context';
import { MuiButton } from '../../../../components/button';
import ChatRoomSkeleton from '../skeleton/chat-room-skeleton';

const text = {
  button: 'اتاق گفتگو',
  loading: 'درحال بارگذاری...',
  error: 'مجدد تلاش کنید',
};

const ChatRoom = ({ token, meeting }) => {
  const [expanded, setExpanded] = useState(false);
  const [messages, setMessages] = useState([]);
  const [value, setValue] = useState('');
  const [wsUrl, setWsUrl] = useState('');
  const [ws, setWs] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user && meeting) {
      setWsUrl(
        `${urlConfig.event_ws_chat}/${meeting.id}/${user.username}/?token=${token}`
      );
    }
  }, [meeting, token, user]);

  const chatBoxRef = useRef(null);

  useEffect(() => {
    if (chatBoxRef.current !== null) {
      scrollToBottom();
    }
  }, [error, isLoading, messages]);

  const scrollToBottom = () => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const checkSocketStatus = (ws) => {
    if (ws.readyState === 0) {
      setIsLoading(true);
      setError(false);
    } else if (ws.readyState === 1) {
      setIsLoading(false);
      setError(false);
    } else {
      setIsLoading(false);
      setError(true);
    }
  };

  const sendMessage = (e) => {
    if (value) {
      setValue('');
      ws.send(
        JSON.stringify({
          content: value,
        })
      );
      setValue('');
    }
  };

  useEffect(() => {
    // Create WebSocket connection
    if (wsUrl) {
      const newSocket = new WebSocket(wsUrl);

      newSocket.onopen = () => {
        // console.log('WebSocket chat room connection established');
        setIsLoading(false);
        setError(false);
      };

      // Set the socket state
      setWs(newSocket);
      // Close the socket connection when component unmounts
      return () => {
        newSocket.close();
        console.log('chat room closed');
      };
    }
  }, [wsUrl]);

  useEffect(() => {
    // Continuously check if connection is closed
    const intervalId = setInterval(() => {
      if (ws && ws.readyState !== WebSocket.OPEN) {
        // Re-establish connection if it's closed
        const newSocket = new WebSocket(wsUrl);
        setWs(newSocket);
        console.log('Re-establish connection');
      }
    }, 15000);

    ws && checkSocketStatus(ws);

    // Clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [ws, wsUrl]);

  useEffect(() => {
    if (ws) {
      checkSocketStatus(ws);

      ws.addEventListener('message', ({ data }) => {
        const message = data && JSON.parse(data);

        getNewMessage(message);
        getCurrentMessage(message);
      });
    } else if (user && meeting && typeof wsUrl !== 'undefined') {
      setError(true);
      setIsLoading(true);
    } else {
    }
  }, [ws, wsUrl]);

  const getNewMessage = ({ message }) => {
    if (message && isObject(message) && message.content) {
      setMessages((prevMessage) => [
        ...prevMessage,
        {
          text: message.content,
          date: JSON.parse(message.date).chat_date_time,
          username: message.username,
        },
      ]);
    }
  };

  const getCurrentMessage = ({ message }) => {
    if (
      message &&
      !isObject(message) &&
      isArray(JSON.parse(message)) &&
      !message.content
    ) {
      let msgObj = [];
      let ids = [];

      JSON.parse(message).forEach(({ fields }) => {
        msgObj.push({
          text: fields.content,
          date: fields.created_at,
          username: fields.username,
        });
        !ids.includes(fields.user) && fields.user && ids.push(fields.user);
        setMessages(msgObj);
      });
    }
  };

  const messagesContainer =
    isArray(messages) && expanded
      ? messages.map((message, i) => <Message message={message} key={i} />)
      : messages
          .slice()
          .reverse()
          .map((message, i) => <Message message={message} key={i} />);

  return (
    <>
      {error || isLoading ? (
        <ChatRoomSkeleton />
      ) : (
        <div className={expanded ? 'chat-room expanded' : 'chat-room'}>
          <div className='expand-control'>
            <MuiButton
              onClick={() => {
                setExpanded(!expanded);
                scrollToBottom();
              }}
              className='expand-btn'
              variant='outlined'
              endIcon={
                expanded ? <ArrowDown /> : <ArrowDown className='arrow-up' />
              }
            >
              {text.button}
            </MuiButton>
          </div>

          <div className='messages' ref={chatBoxRef}>
            {messagesContainer}
          </div>

          <div className='input-box'>
            <div className='chat-box'>
              {value ? (
                <IconButton
                  onClick={sendMessage}
                  sx={{ mr: 3 }}
                  className='send-btn'
                >
                  <SendMessageIcon />
                </IconButton>
              ) : (
                <div className='multi-media'>
                  <IconButton sx={{ p: '0px', mr: 2 }}>
                    <Recorder />
                  </IconButton>
                  <IconButton sx={{ p: '0px', mr: 3 }}>
                    <Attachment />
                  </IconButton>
                </div>
              )}

              <InputBase
                className='message'
                placeholder='پیام'
                sx={{ mr: 1, flex: 1 }}
                fullWidth
                multiline
                maxRows={6}
                value={value}
                type='text'
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                inputProps={{
                  inputMode: 'text',
                  autoComplete: 'off',
                  autoCorrect: 'off',
                  autoCapitalize: 'off',
                  spellCheck: false,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatRoom;
