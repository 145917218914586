import Cookies from 'js-cookie';

import { CreateMeetingContextProvider } from '../../services/create-meeting/create-meeting.context';
import { removeCookies } from '../../utils/fetch-request';

export const CreateMeetingLayout = ({ children }) => {
  if (!Cookies.get('_mzb_token')) {
    removeCookies();
  }

  return (
    <CreateMeetingContextProvider>{children}</CreateMeetingContextProvider>
  );
};
