import { useContext } from 'react';
import { Box } from '@mui/material';

import { CreateMeetingContext } from '../../../../services/create-meeting/create-meeting.context';
import { ReactComponent as Close } from '../../../../assets/icon/close.svg';
import { MuiTypography } from '../../../../components/typography/typography';

export const InvitedList = () => {
  const { meeting, setMeeting } = useContext(CreateMeetingContext);
  const { contacts } = meeting;

  const removeContacts = (phoneNumber) => {
    const newPhoneNumbers = meeting.contacts.filter(
      (contact) => contact.phoneNumber !== phoneNumber
    );

    const newNonMembers = meeting.nonMembers.filter((i) => i !== phoneNumber);

    const uids = meeting.invited_users.filter(
      (i) => i.phoneNumber !== phoneNumber
    );

    setMeeting({
      ...meeting,
      contacts: newPhoneNumbers,
      nonMembers: newNonMembers,
      invited_users: uids,
    });
  };

  const contact =
    contacts.length > 0
      ? contacts.map((contact, i) => {
          return (
            <div key={i} className='invited-user-btn'>
              <MuiTypography value='bodySm' className='invited-user-text'>
                {contact.username ? contact.username : contact.phoneNumber}
              </MuiTypography>
              <Close
                className='close-icon'
                cursor={'pointer'}
                onClick={() => removeContacts(contact.phoneNumber)}
              />
            </div>
          );
        })
      : null;

  return (
    <Box className='invited-user-box' spacing={2}>
      {contact}
    </Box>
  );
};
