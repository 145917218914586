import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Divider, Stack } from '@mui/material';

import { ReactComponent as Settings } from '../../assets/icon/sidebar/setting.svg';
import { ReactComponent as Rules } from '../../assets/icon/sidebar/task.svg';
import { ReactComponent as Support } from '../../assets/icon/sidebar/support.svg';
import { ReactComponent as Close } from '../../assets/icon/close-square.svg';
import { LogoutDialog } from './logout-dialog';
import { MuiTypography } from '../typography/typography';

const menuItems = [
  {
    text: 'تنظیمات',
    path: '/',
    Icon: <Settings />,
  },
  {
    text: 'قوانین و مقررات',
    path: '/',
    Icon: <Rules />,
  },
  {
    text: 'پشتیبانی',
    path: '/',
    Icon: <Support />,
  },
];

const logout = {
  text: 'خروج از حساب کاربری',
  Icon: <Close />,
};

export const Menu = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const MenuItem = ({ item }) => (
    <ListItem
      key={item.text}
      // onClick={() => navigate(item.path)}
      className='menu-item'
      disablepadding='true'
    >
      <ListItemButton disablepadding='true'>
        {item.Icon}
        <MuiTypography value='bodyMd'>{item.text}</MuiTypography>
      </ListItemButton>
    </ListItem>
  );

  return (
    <div className='menu'>
      <List disablepadding='true'>
        <Stack
          className='menu-stack'
          divider={<Divider className='divider' flexItem />}
        >
          {menuItems.map((item, index) => (
            <MenuItem item={item} key={index} />
          ))}

          <ListItem className='menu-item'>
            <ListItemButton
              onClick={() => setOpenDialog(true)}
              disablepadding='true'
              className='logout'
              id='logout'
            >
              {logout.Icon}
              <MuiTypography value='bodyMd'>{logout.text}</MuiTypography>
            </ListItemButton>
          </ListItem>

          <LogoutDialog open={openDialog} setOpen ={setOpenDialog} />
        </Stack>
      </List>
    </div>
  );
};
