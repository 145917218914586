import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import './index.scss';
import { Users } from '../users';
import { isArray } from '../../../../utils/utility';
import { ReactComponent as Location } from '../../../../assets/icon/details/location.svg';
import { ReactComponent as Calendar } from '../../../../assets/icon/details/calendar.svg';
import { dateConvertor } from '../../../../services/meetings/meetings.services';
import { MuiTypography } from '../../../../components/typography/typography';

export const Details = ({ meeting }) => {
  const { date_time, location, members } = meeting;
  const [place, setPlace] = useState('');

  useEffect(() => {
    if (isArray(location)) {
      let item = location.find((item) => item.status === 4);
      item &&
        setPlace({
          address: item.address,
          name: item.name,
        });
    }
  }, [location]);

  return (
    <div className='details'>
      {place ? (
        <Box
          className='details-info'
          border='1px solid #E4E4E4'
          borderRadius={'8px'}
        >
          <Location />

          <div className='info-text'>
            <MuiTypography value='h7'>{place.name}</MuiTypography>
            <MuiTypography value='bodySm'>{place.address} </MuiTypography>
          </div>
        </Box>
      ) : null}

      <Box
        className='details-info'
        border='1px solid #E4E4E4'
        borderRadius={'8px'}
      >
        <Calendar />
        <MuiTypography value='bodySm'>
          {date_time && dateConvertor(date_time)}
        </MuiTypography>
      </Box>

      {members ? (
        <Box
          className='details-info'
          border='1px solid #E4E4E4'
          borderRadius={'8px'}
        >
          <Users members={members} />
        </Box>
      ) : null}
    </div>
  );
};
