import { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ContactPicker } from '../components/add-friends/contact-picker';
import { Suggestion } from '../components/add-friends/suggestion';
import { InvitedList } from '../components/add-friends/invited-list';
import { SuggestionEmptyState } from '../components/add-friends/suggestion-empty-state';
import { MuiButton } from '../../../components/button';
import { AuthContext } from '../../../services/authentication/auth.context';
import { CreateMeetingContext } from '../../../services/create-meeting/create-meeting.context';
import { ReactComponent as BackIcon } from '../../../assets/icon/back.svg';
import { getUsersData, isArray } from '../../../utils/utility';
import { MuiTypography } from '../../../components/typography/typography';
import Alert from '../../../components/alert';

const text = {
  next: 'ادامه',
  invited: 'دعوتی‌ها',
  limitNumberOfInvitations:
    'برای ایجاد دورهمی، حداکثر ۱۰ نفر رو میتونی دعوت کنی.',
};

export const AddFriends = ({ setOpenCreateMeeting }) => {
  const navigate = useNavigate();

  const { friends, fetch } = useContext(AuthContext);
  const {
    meeting,
    setMeeting,
    isExist,
    limitNumberOfInvitations,
    setLimitNumberOfInvitations,
  } = useContext(CreateMeetingContext);

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState('none');

  const handleClick = async () => {
    setIsLoading(true);

    const phoneNumbers =
      meeting.contacts.length > 0 && meeting.contacts.map((i) => i.phoneNumber);

    const response = await getUsersData('phonenumbers', phoneNumbers, fetch);

    try {
      if (response.data && isArray(response.data)) {
        let uids = [];
        let ids = [];
        let phoneNumbers = [];

        response.data.forEach((user) => {
          uids.push({
            uid: user.uid,
            phoneNumber: user.phonenumber,
          });
          ids.push(user.pk);
          phoneNumbers.push(user.phonenumber);
        });

        let newNonMember = meeting.nonMembers;
        // Check if array none member includes array phoneNumbers
        phoneNumbers.forEach((i) => {
          if (newNonMember.includes(i)) {
            newNonMember = newNonMember.filter((n) => n !== i);
          }
        });

        setMeeting({
          ...meeting,
          invited_users: uids,
          users_ids: ids,
          nonMembers: newNonMember,
        });
      }

      setOpenCreateMeeting(true);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  return (
    <div className='add-friends-layout'>
      {limitNumberOfInvitations ? (
        <Alert
          text={text.limitNumberOfInvitations}
          id='error'
          setCloseAlert={setLimitNumberOfInvitations}
        />
      ) : null}

      <Box className='add-friend-header' mb={4}>
        <BackIcon
          cursor={'pointer'}
          id='back'
          onClick={() => navigate('/meetings', { replace: true })}
        />
        <ContactPicker open={open} setOpen={setOpen} />
      </Box>

      {isExist ? (
        <div className='invited-list-container'>
          <MuiTypography value='buttonLg'>{text.invited}</MuiTypography>
          <InvitedList />
        </div>
      ) : null}

      {isArray(friends) && <Suggestion />}

      {!isExist ? <SuggestionEmptyState open={open} /> : null}

      <div className='add-friend-next'>
        <MuiButton
          onClick={handleClick}
          loading={isLoading}
          disabled={!isExist}
          variant='contained'
          fullWidth
        >
          {text.next}
        </MuiButton>
      </div>
    </div>
  );
};
