import { useContext } from 'react';
import { Stack, Avatar } from '@mui/material';

import { CreateMeetingContext } from '../../../../services/create-meeting/create-meeting.context';
import { MuiButton } from '../../../../components/button';
import { MuiTypography } from '../../../../components/typography/typography';

const text = {
  add: 'اضافه کردن',
  added: 'اضافه شده',
};

export const SuggestionItem = ({
  friend,
  defaultHeight,
  setDefaultHeight,
  isAddedHeight,
  setIsAddedHeight,
}) => {
  const { meeting, updateContacts } = useContext(CreateMeetingContext);
  const { contacts } = meeting;
  const { uid, phoneNumber, username, avatar } = friend;

  let phoneNumbers = [];

  contacts.map((contact) => phoneNumbers.push(contact.phoneNumber));

  const handleClick = () => {
    updateContacts(phoneNumber, username, uid);

    if (!isAddedHeight) {
      setDefaultHeight(defaultHeight + 22);
      setIsAddedHeight(true);
    }
  };

  return (
    <Stack direction='row' spacing={1}>
      <Stack direction='row' flexGrow={1} alignItems='center'>
        <Avatar src={avatar} className='suggestion-avatar' />
        <MuiTypography value='bodyMd'>{username}</MuiTypography>
      </Stack>

      <MuiButton
        onClick={handleClick}
        className='add-suggestion-friend-btn'
        variant={
          phoneNumbers.length > 0 && phoneNumbers.includes(phoneNumber)
            ? 'contained'
            : 'outlined'
        }
      >
        {phoneNumbers.length > 0 && phoneNumbers.includes(phoneNumber)
          ? text.added
          : text.add}
      </MuiButton>
    </Stack>
  );
};
