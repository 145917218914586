import Cookies from 'js-cookie';

import { isObject } from '../../utils/utility';
import axios from 'axios';
import { urlConfig } from '../../utils/env';
import { Navigate } from 'react-router-dom';

export const userDataTransform = (data) => {
  const { phonenumber, uid, lat, lng, avatar, username, pk } = data;
  const user = {
    username,
    mobilePhone: phonenumber,
    uid,
    lat,
    lng,
    avatar,
    pk,
  };

  return user;
};

export const otpCodeValidator = (number) => {
  const regex = /^\d{6}$/;
  if (regex.test(number.toString())) {
    return !!parseInt(number, 10);
  }
  return false;
};

export const setToken = (token) => {
  Cookies.set('_mzb_token', token, {
    expires: 365, // Set the expiration time in seconds
    secure: true, // Set the secure flag for HTTPS
    sameSite: 'strict', // Set the SameSite attribute for additional security
  });
};

export const isValidResponse = (res) => {
  return (
    (res.status === 200 || res.status === 201) &&
    isObject(res.data) &&
    isObject(res.data.user)
  );
};

export const userNameValidation = (username) => {
  const regex =
    /^(?!.*[?.]{2,})(?=.*[a-zA-Z])(?=.*\d|[a-zA-Z_])[a-zA-Z\d_]{4,25}$/;

  return regex.test(username);
};

//=====================================
//OTP

export const sendAuthenticationCode = (
  value,
  setStatus,
  setAccessToken,
  setUser,
  mobilePhone
) => {
  if (otpCodeValidator(value)) {
    setStatus(false);
    axios({
      method: 'post',
      url: urlConfig.code_verify,
      data: { activation_code: value, phonenumber: `+98${mobilePhone}` },
    })
    .then((res) => {
        if (isValidResponse(res)) {
          setToken(res.data.refresh);
          setAccessToken(res.data.access);
          if (!res.data.user.username) {
            setUser(userDataTransform(res.data.user));
            return <Navigate to='register' replace />;
          } else {
            setUser(userDataTransform(res.data.user));
            return <Navigate to='meetings' replace />;
          }
        }
      })
      .catch((e) => {
        showError(e, setStatus);
      });
  }
};

const showError = (e, setStatus) => {
  if (e.response) {
    if (e.response.status === 400 && 'non_field_errors' in e.response.data) {
      setStatus(1);
    } else if (e.response.status === 429) {
      if (e.response.data.status === 0) {
        setStatus(2);
      } else {
        setStatus(3);
      }
    }
  }
};
