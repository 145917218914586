import { Typography } from '@mui/material';

import './styles.scss';
import { Link } from 'react-router-dom';

export const MuiTypography = ({
  children,
  value,
  component,
  noWrap,
  sx,
  onClick,
  id,
}) => {
  const variant = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h4',
    h4: 'h5',
    h5: 'h6',
    h6: 'subtitle1',
    h7: 'body1',
    bodyXl: 'h6',
    bodyMd: 'body1',
    bodyLg: 'h6',
    bodyXs: 'caption',
    bodySm: 'body2',
    captionMd: 'caption',
    captionLg: 'button',
    buttonSm: 'button',
    buttonLg: 'body1',
    alert: 'subtitle2',
  };

  return (
    <Typography
      variant={variant[value]}
      className={value && `p-${value}`}
      id={id && `p-${id}`}
      component={component && component}
      noWrap={noWrap}
      sx={sx && sx}
      onClick={() => onClick && onClick()}
    >
      {children}
    </Typography>
  );
};

export const ResendCode = ({ children, disabled, className, onClick }) => (
  <Link
    disabled={disabled}
    onClick={() => onClick && onClick()}
    className={className}
  >
    {children}
  </Link>
);
