export const handleValidation = (value) => {
  const regex = /^9[0-9]{9}$/;
  return regex.test(value);
};

export const removeSpecialCharacter = (phone) => {
  const result = (phone) =>
    phone.replace(/^(?:\+98|98|0)+/, '').replace(/\D/g, '');
  //
  return result(result(phone));
};
