import { useContext } from 'react';
import { Marker } from 'react-leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { CreateMeetingContext } from '../../../../services/create-meeting/create-meeting.context';
import { isArray } from '../../../../utils/utility';

export const CreateMeetingMapMarker = () => {
  const { meeting } = useContext(CreateMeetingContext);
  const { locations } = meeting;

  const marker =
    isArray(locations) &&
    locations.map((location, i) => (
      <Marker
        key={i}
        position={[location.lat, location.lng]}
        icon={
          new Icon({
            iconUrl: markerIconPng,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
          })
        }
      ></Marker>
    ));

  return <>{marker}</>;
};
