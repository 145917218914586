import { Backdrop, Drawer as MuiDrawer, IconButton } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import './drawer.scss';
import { Menu } from './menu';
import { UserInformation } from './user-information';

export const Drawer = ({ open, handleDrawerClose }) => {
  return (
    <div className='profile-drawer'>
      <MuiDrawer
        sx={{
          width: 320,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 320,
            boxSizing: 'border-box',
            position: 'absolute',
          },
          '& .MuiDrawer-root': {
            position: 'absolute',
          },
        }}
        className={open ? 'open-drawer' : 'close-drawer'}
        variant='persistent'
        anchor='left'
        open={open}
      >
        <IconButton className='close-btn' onClick={handleDrawerClose}>
          <HighlightOffOutlinedIcon />
        </IconButton>

        <UserInformation />

        <Menu />
      </MuiDrawer>
      <Backdrop
        sx={{
          color: '#fdfdfd',
          zIndex: 10,
          position: 'absolute',
          background: 'rgba(18, 18, 18, 0.2)',
        }}
        open={open}
      />
    </div>
  );
};
