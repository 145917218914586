import { urlConfig } from './env';
import { removeUser, request } from './fetch-request';

export const isArray = (arr) => {
  return (
    Array.isArray(arr) &&
    arr !== undefined &&
    arr !== null &&
    arr.length > 0 &&
    !arr.includes(undefined)
  );
};

export const isObject = (obj) => {
  return (
    obj !== undefined &&
    obj !== null &&
    typeof obj === 'object' &&
    Object.keys(obj).length > 0
  );
};

export const fetchUsersData = async (key, value, setUsersInfo, fetch) => {
  try {
    const response = await fetch(urlConfig.get_users_data, 'post', {
      [key]: value,
    });

    if (isArray(response.data)) {
      setUsersInfo((userData) => [...userData, ...response.data]);
    }
  } catch (e) {
    removeUser(e);
  }
};

export const getUsersData = async (key, value, fetch) => {
  try {
    const response = await fetch(urlConfig.get_users_data, 'post', {
      [key]: value,
    });
    return response;
  } catch (e) {
    removeUser(e);
  }
};

export const getMeetingDetails = async (
  id,
  setMeeting,
  setIsLoading,
  setNotFound
) => {
  setIsLoading(true);
  request(`${urlConfig.meeting_details}${id}/`, 'get')
    .then((res) => {
      if (isObject(res.data)) {
        setIsLoading(false);
        setMeeting(res.data);
        setIsLoading(false);
      }
    })
    .catch((e) => {
      setIsLoading(false);
      if (e.response && e.response.status === 404) {
        setNotFound(true);
      }
      removeUser(e);
    });
};
