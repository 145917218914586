import { useMediaQuery } from '@mui/material';

import { MuiTypography } from '../../../../components/typography/typography';

const Detail = ({ title, description }) => {
  const isLargeScreen = useMediaQuery('(min-width: 1200px)');

  return (
    <div className='detail'>
      <MuiTypography value={isLargeScreen ? 'buttonLg' : 'buttonSm'} id='detail-detail'>
        {title}
      </MuiTypography>
      <MuiTypography value={isLargeScreen ? 'body1' : 'bodySm'} id='detail-description'>
        {description}
      </MuiTypography>
    </div>
  );
};

export default Detail;
