import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import './index.scss';
import { CreateMeetingLayout } from '../../../components/layouts/create-meeting.layout';
import Actions from '../components/actions';
import ChatRoom from '../components/chat-room';
import { VotingList } from '../components/voting-list/voting-list';
import Alert from '../../../components/alert';
import { MuiTypography } from '../../../components/typography/typography';
import ErrorPage from '../../../components/error/error-page';
import { VotingSkeleton } from '../components/skeleton/voting-skeleton';
import { NotificationsContext } from '../../../services/notification/notifications.context';
import { getMeetingDetails, isArray } from '../../../utils/utility';
import { urlConfig } from '../../../utils/env';

const text = {
  invitationSentSuccessfully: 'دعوت نامه شما با موفقیت ارسال شد.',
  error: 'مجدد تلاش کنید',
};

const Voting = () => {
  const { state } = useLocation();
  const { id } = useParams();

  const { showAlert, meeting: data } = state || '';

  const [places, setPlaces] = useState([]);
  const [meeting, setMeeting] = useState(data);
  const [ws, setWs] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [closeAlert, setCloseAlert] = useState(showAlert);
  const [notFound, setNotFound] = useState(false);
  const navigate = useNavigate();

  const { completedNotification } = useContext(NotificationsContext);

  const token = Cookies.get('_mzb_token');

  const wsUrl = `${urlConfig.event_ws_vote}/${id}/?token=${token}`;

  const checkSocketStatus = (ws) => {
    if (ws.readyState === 0) {
      setIsLoading(true);
      setError(false);
    } else if (ws.readyState === 1) {
      setIsLoading(false);
      setError(false);
    } else {
      setIsLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    // Create WebSocket connection
    const newSocket = new WebSocket(wsUrl);

    newSocket.onopen = () =>
      console.log('WebSocket voting room connection established');

    // Set the socket state
    setWs(newSocket);

    if (id) {
      getMeetingDetails(id, setMeeting, setIsLoading, setNotFound);
    }

    // Close the socket connection when component unmounts
    return () => {
      newSocket.close();
      console.log('voting room closed');
    };
  }, [id, state, wsUrl]);

  useEffect(() => {
    // Continuously check if connection is closed
    const intervalId = setInterval(() => {
      if (ws && ws.readyState !== WebSocket.OPEN) {
        // Re-establish connection if it's closed
        const newSocket = new WebSocket(wsUrl);
        setWs(newSocket);
        console.log('Re-establish connection');
      }
    }, 15000);

    ws && checkSocketStatus(ws);

    // Clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [ws, wsUrl]);

  useEffect(() => {
    if (ws) {
      ws.addEventListener('message', ({ data }) => {
        ws && checkSocketStatus(ws);

        const message = JSON.parse(data).message;

        if (message && message.action === 'poll') {
          let id =
            message.payload.location_id &&
            parseInt(message.payload.location_id);
          let vote = message.count && message.count;

          let lastVoteId = parseInt(message.update.pre_location_id);
          let lastVoteCount = message.update.pre_location_count;

          const newVotes = isArray(places)
            ? places.map((place) => {
                if (place.id === id) {
                  return { ...place, vote: vote };
                } else if (place.id === lastVoteId) {
                  return { ...place, vote: lastVoteCount };
                }
                return place;
              })
            : null;

          newVotes && setPlaces(newVotes);
        }
      });
    }
  }, [places, ws]);

  useEffect(() => {
    if (meeting && isArray(meeting.location)) {
      let placesArr = [];
      meeting.location.forEach((location) => {
        if (location.status && location.status === 1) {
          placesArr.push({
            id: location.id && parseInt(location.id),
            vote: location.vote && location.vote,
            name: location.name && location.name,
            city: location.city && location.city,
          });
        }
      });
      placesArr.length > 0 && setPlaces(placesArr);
    }
  }, [meeting]);

  useEffect(() => {
    let isMeetingDone = false;
    isMeetingDone =
      completedNotification &&
      completedNotification.length > 0 &&
      completedNotification.some(
        (notification) => parseInt(notification.meeting_id) === parseInt(id)
      );

    isMeetingDone &&
      navigate(`/meetings/details/${id}`, {
        state: {
          showBottomAppBar: true,
        },
      });
  }, [completedNotification, id, navigate]);

  return notFound ? (
    <ErrorPage status='forbiddenError' />
  ) : (
    <>
      {isLoading ? (
        <VotingSkeleton />
      ) : (
        <div className='voting-layout'>
          <CreateMeetingLayout>
            {closeAlert ? (
              <Alert
                id='success'
                text={text.invitationSentSuccessfully}
                setCloseAlert={setCloseAlert}
              />
            ) : null}

            <Actions
              meeting={meeting}
              places={places}
              setPlaces={setPlaces}
              alert={closeAlert}
            />

            {error ? (
              <MuiTypography value='bodySm'>{text.error}</MuiTypography>
            ) : null}

            {places ? <VotingList places={places} ws={ws} /> : null}

            <ChatRoom token={token} meeting={meeting} />
          </CreateMeetingLayout>
        </div>
      )}
    </>
  );
};

export default Voting;
