import axios from 'axios';

import { isArray, isObject } from '../../utils/utility';
import { urlConfig } from '../../utils/env';

// const cities = require('../../locations-data/cities.json');

const addLocations = (result, setLocations) => {
  let locationArr = [];
  let locationObj = {};

  isArray(result) &&
    result.map((item) => {
      if (item && isObject(item)) {
        let { city, county, address, location, name } = item;

        locationObj = {
          name,
          address,
          lat: location.coordinates[1],
          lng: location.coordinates[0],
          city: city ? city : county,
        };

        name && locationArr.push(locationObj);

        setLocations(locationArr);
      }

      return null;
    });
};

export const getLocations = (
  value,
  category,
  setIsLoading,
  setLocations,
  setOpen,
  setValue
) => {
  if (category && value) {
    setIsLoading(true);
    axios
      .request({
        method: 'GET',
        url: 'https://map.ir/places/list',
        params: {
          $skip: '0',
          $top: '20',
          $filter: `lat eq ${value.lat} and lon eq ${value.lng} and subcategory eq ${category} and buffer eq 15000 and sort eq true`,
        },
        headers: {
          'x-api-key': urlConfig.mapApiKey,
        },
      })
      .then((response) => {
        setOpen && setOpen(false);
        setIsLoading(false);

        if (response.data.value && isArray(response.data.value)) {
          addLocations(response.data.value, setLocations);
          if (setValue) {
            response.data.value.forEach((item) => {
              // setValue(item.city);
            });
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
        setIsLoading(false);
      });
  }
};

//====================================================
// const findState = (userLocation, setValue) => {
//   axios
//     .get(
//       `https://api.geoapify.com/v1/geocode/reverse?lat=${userLocation.lat}&lon=${userLocation.lng}&type=state&format=json&apiKey=${geoapifyKey}`
//     )
//     .then((result) => {
//       if (
//         result.data.results &&
//         result.data.results.length > 0 &&
//         result.data.results[0].state
//       ) {
//         let state = result.data.results.state;
//         let filteredCity = cities.find((city) => city.state === state);
//         filteredCity.length > 0 && setValue(filteredCity.id);
//       }
//     })
//     .catch((error) => console.log('error', error));
// };

//====================================================
export const findCity = (
  userLocation,
  setValue,
  setLocations,
  setIsLoading,
  value
) => {
  // const getFirst2Digits = (number) => {
  //   return Number(String(number).slice(0, 2));
  // };

  // let uLat = getFirst2Digits(userLocation.lat);
  // let uLng = getFirst2Digits(userLocation.lng);

  // let city = cities.find(
  //   (city, i) =>
  //     getFirst2Digits(city.lat) === uLat && getFirst2Digits(city.lng) === uLng
  // );

  if (userLocation && userLocation.lat && userLocation.lng) {
    getLocations(
      {
        lat: userLocation.lat,
        lng: userLocation.lng,
      },
      'restaurant',
      setIsLoading,
      setLocations,
      null,
      setValue
    );
  } else {
    getLocations(value, 'restaurant', setIsLoading, setLocations, null);
    alert('لوکیشن شما پیدا نشد.');
  }
};

//====================================================
export const getUserLocation = (
  value,
  setValue,
  setOpen,
  setLocations,
  setIsLoading
) => {
  setOpen(false);
  const success = (position) => {
    const { coords } = position;

    let userLocation = {
      lat: coords.latitude,
      lng: coords.longitude,
    };

    findCity(userLocation, setValue, setLocations, setIsLoading, value);
  };
  const error = (error) => {
    console.log(error);
    getLocations(value, 'restaurant', setIsLoading, setLocations, null);
  };

  navigator.geolocation.getCurrentPosition(success, error);
};
//====================================================
