import '../styles/main.scss';
import Navbar from '../component/navbar';
import Header from '../component/header';
import Footer from '../component/footer';
import Features from '../component/features';
import MizbanTraget from '../component/mizban-target';
import ReadyToLogin from '../component/readyToLogin';

const Main = () => {
  return (
    <div className='container'>
      <Navbar />

      <main className='main'>
        <Header />
        <Features />
        <MizbanTraget />
        <ReadyToLogin />
        <Footer />
      </main>
    </div>
  );
};

export default Main;
