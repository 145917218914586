import { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import { MeetingsContext } from '../meetings/meetings.context';
import { AuthContext } from '../authentication/auth.context';
import { fetchUsersData, isArray, isObject } from '../../utils/utility';
import {
  getCreatedMeetingNotification,
  getInvitedMeetingNotification,
} from './notifications.services';
import { urlConfig } from '../../utils/env';
import { removeUser, request } from '../../utils/fetch-request';

export const NotificationsContext = createContext();

export const NotificationsContextProvider = ({ children }) => {
  const token = Cookies.get('_mzb_token');
  const [completedNotification, setCompletedNotification] = useState([]);
  const [invitationNotification, setInvitationNotification] = useState([]);
  const [homeNotification, setHomeNotification] = useState([]);
  const [notificationsId, setNotificationsId] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [ws, setWs] = useState();

  const navigate = useNavigate();

  const {  setUsersInfo } = useContext(MeetingsContext);
  const { fetch } = useContext(AuthContext);

  const wsUrl = `${urlConfig.event_ws_notification}/?token=${token}`;

  useEffect(() => {
    isArray(userIds) &&
      fetchUsersData('users_ids', userIds, setUsersInfo, fetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIds]);

  useEffect(() => {
    let notificationArr = [];

    invitationNotification.length > 0 &&
      invitationNotification.forEach((i) => {
        if ('status' in i && i.status === 0) {
          notificationArr.push(i);
        } else {
          i.action === 'create' && i.status === 0 && notificationArr.push(i); //todo
        }
      });
    setHomeNotification(notificationArr);
  }, [invitationNotification]);

  useEffect(() => {
    // Create WebSocket connection
    const newSocket = new WebSocket(wsUrl);

    newSocket.onopen = () =>
      console.log('WebSocket notification connection established');
    // Set the socket state
    setWs(newSocket);

    // Close the socket connection when component unmounts
    // return () => {
    //   newSocket.close();
    //   console.log('notification closed');
    // };
  }, [wsUrl]);

  useEffect(() => {
    // Continuously check if connection is closed
    const intervalId = setInterval(() => {
      if (ws && ws.readyState !== WebSocket.OPEN) {
        // Re-establish connection if it's closed
        const newSocket = new WebSocket(wsUrl);
        setWs(newSocket);
        console.log('Re-establish connection');
      }
    }, 15000);

    // Clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [ws, wsUrl]);

  useEffect(() => {
    if (ws) {
      ws.addEventListener('message', handleWebSocketMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws]);

  const acceptRejectMeeting = (id, status, meetingId, setIsLoading) => {
    setIsLoading(true);

    request(`${urlConfig.meeting_details}${meetingId}/${status}/`, 'post')
      .then(() => {
        minimizeNotification(id);

        let newNotifications = invitationNotification.filter(
          (item) => item.notification_id !== id
        );

        setInvitationNotification(newNotifications);

        status === 'accept' && navigate(`/vote/${meetingId}`);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        removeUser(e);
        setIsLoading(false);
      });
  };

  const handleWebSocketMessage = ({ data }) => {
    const notification = data && JSON.parse(data);

    if (notification && notification.message) {
      if (
        notification.message.notifications &&
        notification.message.notifications.length > 0
      ) {
        getInvitedMeetingNotification(
          notification.message.notifications,
          setInvitationNotification,
          setCompletedNotification,
          setNotificationsId,
          setUserIds
        );
      } else if (
        !notification.message.notifications &&
        isObject(JSON.parse(notification.message))
      ) {
        getCreatedMeetingNotification(
          notification.message,
          setInvitationNotification,
          setCompletedNotification,
          setUsersInfo,
          setNotificationsId,
          fetch,
          userIds,
          setUserIds
        );
      }
    }
  };

  const minimizeNotification = (id) => {
    const updateArr = (arr, setArr) => {
      let newArr = [];

      arr.forEach((obj) => newArr.push({ ...obj, status: 1 }));

      setArr(newArr);
    };

    if (id) {
      const updateHomeNotificationsStatus = homeNotification.filter(
        (i) => i.notification_id !== id
      );
      id && setHomeNotification(updateHomeNotificationsStatus);
    } else {
      updateArr(invitationNotification, setInvitationNotification);
      setHomeNotification([]);
    }

    ws &&
      ws.send(
        JSON.stringify({
          action: 'seen',
          payload: {
            ids: id ? [id] : notificationsId,
          },
        })
      );
  };

  return (
    <NotificationsContext.Provider
      value={{
        ws,
        invitationNotification,
        completedNotification,
        homeNotification,
        acceptRejectMeeting,
        minimizeNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
