import { styled } from '@mui/system';

import { ReactComponent as MeetingEmptyState } from '../../../assets/images/meetings-empty-state.svg';
import { MuiTypography } from '../../../components/typography/typography';

const text = {
  memories: 'هنوز در دورهمی شرکت نکرده‌اید.',
  meeting: 'دورهمی فعالی ندارید.',
};

const Container = styled('div')({
  top: '40%',
  left: '50%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  transform: 'translateX(-50%) translateY(-50%)',
  MozTransform: 'translateX(-50%) translateY(-50%)',
  WebkitTransform: 'translateX(-50%) translateY(-50%)',
});

export const EmptyState = ({ status }) => {
  return (
    <Container>
      <MeetingEmptyState />
      <MuiTypography value='captionLg' style={{ textAlign: 'center' }}>
        {status ? text.memories : text.meeting}
      </MuiTypography>
    </Container>
  );
};
