import './index.scss';
import { RectangleSkeleton } from '../../../components/skeleton/skeleton';

const notificationButton = (
  <RectangleSkeleton className='notification-button' />
);

export const NotificationSkeleton = () => {
  return (
    <div className='notification-skeleton-container'>
      <RectangleSkeleton />
      <div className='row1'>
        {notificationButton}
        {notificationButton}
      </div>

      <RectangleSkeleton />
      <div className='row1 row2'>{notificationButton}</div>

      <RectangleSkeleton />
      <div className='row1 row2'>
        {notificationButton}
        {notificationButton}
      </div>
    </div>
  );
};
