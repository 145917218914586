import { fetchUsersData, isArray, isObject } from '../../utils/utility';

export const dateConvertor = (date) => {
  const option = [
    { weekday: 'short' },
    { day: '2-digit' },
    { month: 'long' },
    {
      year: 'numeric',
    },
  ];
  // Subtract one day from the input date
  const correctedDate = new Date(date);
  correctedDate.setDate(correctedDate.getDate() - 1);

  const changeDateFormat = (option) =>
    new Intl.DateTimeFormat('fa-IR', option).format(correctedDate);

  let arr = [];
  option.map((i) => arr.push(changeDateFormat(i)));

  return arr.join(' ');
};

// Prevent adding rejected or invited meetings to the list
export const filterMeetings = (meetings, user) => {
  let filteredMeetings = [];

  isArray(meetings) &&
    meetings.forEach((meeting) => {
      if (meeting.members && meeting.members.length > 0) {
        meeting.members.forEach((member) => {
          if (member.member_uuid === user.uid) {
            if (member.status === 0 || member.status === 1) {
              filteredMeetings.push(meeting);
            }
          }
        });
      }
    });

  return filteredMeetings;
};

export const getMeetingUsersData = (
  meetings,
  uids,
  setUids,
  setUsersInfo,
  fetch
) => {
  let uidsArr = uids;

  if (isArray(meetings)) {
    meetings.forEach((meeting) => {
      if (isObject(meeting) && isArray(meeting.members)) {
        meeting.members.forEach((i) => {
          if (i.member_uuid) {
            !uidsArr.includes(i.member_uuid) && uidsArr.push(i.member_uuid);
          }
        });
      }
    });
  }
  if (isArray(uidsArr)) {
    setUids(uidsArr);
    fetchUsersData('users_uuids', uidsArr, setUsersInfo, fetch);
  }
};
