import { useState, useContext } from 'react';
import VerificationInput from 'react-verification-input';

import './index.scss';
import ErrorComponent from './error';
import { VerificationTimer } from './verification-timer';
import { MuiTypography } from '../../../../components/typography/typography';
import { text } from '../../../../texts/otp';
import { AuthContext } from '../../../../services/authentication/auth.context';
import {
  sendAuthenticationCode,
} from '../../../../services/authentication/auth.service';

export const Verification = () => {
  const [code, setCode] = useState('');
  const [status, setStatus] = useState(false);

  const { mobilePhone, setUser, setIsEnterMobilePhoneByUser, setAccessToken } =
    useContext(AuthContext);

  const onChange = (value) => {
    setCode(value);
    sendAuthenticationCode(
      value,
      setStatus,
      setAccessToken,
      setUser,
      mobilePhone
    );
  };

  // useEffect(() => {
  //   autoReadSMS(setCode);
  // }, []);

  return (
    <div className='verification'>
      <ErrorComponent status={status} setStatus={setStatus} />

      <MuiTypography component='h1' value='h6'>
        {text.login}
      </MuiTypography>
      <MuiTypography value='bodyMd'>{text.title}</MuiTypography>

      <div className='edit-phone'>
        <MuiTypography
          value='captionMd'
          onClick={() => setIsEnterMobilePhoneByUser(false)}
        >
          {text.editMobilePhone}
        </MuiTypography>
        <MuiTypography value='captionMd'>98{mobilePhone}+</MuiTypography>
      </div>

      <VerificationInput
        value={code}
        onChange={onChange}
        length={6}
        validChars={'0-9'}
        autoComplete='one-time-code'
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
        placeholder=''
        autoFocus
        classNames={{
          container: 'verification-container',
          character: 'verification-char',
          characterSelected: 'verification-char-selected',
          characterInactive: 'verification-char-interactive',
        }}
      />

      <VerificationTimer />
    </div>
  );
};
