import { useContext } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { CreateMeetingMapMarker } from './create-meeting-map-marker';
import { CreateMeetingContext } from '../../../../services/create-meeting/create-meeting.context';
import { AuthContext } from '../../../../services/authentication/auth.context';
// const ChangePosition = ({ position }) => {
//   const map = useMap();
//   map.flyTo(position, map.getZoom());
// };

const FitBound = ({ locations }) => {
	const map = useMap();
	locations.length > 0 && map.fitBounds(locations, { padding: [60, 75] });
};

export const CreateMeetingMap = () => {
	const { meeting } = useContext(CreateMeetingContext);
	const { locations } = meeting;
	const { default_location } = useContext(AuthContext);

	return (
		<div className='create-meeting-map'>
			<MapContainer center={default_location} zoom={15} scrollWheelZoom={true} className='create-meeting-map'>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				/>
				<CreateMeetingMapMarker />

				<FitBound locations={locations} />
			</MapContainer>
		</div>
	);
};
