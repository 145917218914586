import { useContext } from 'react';
import { Box } from '@mui/material';

import { AuthContext } from '../../../../services/authentication/auth.context';
import moment from 'moment';
import { MuiTypography } from '../../../../components/typography/typography';

// const timeOfDay = {
//   morning: 'صبح',
//   afternoon: 'بعد از ظهر',
//   night: 'شب',
// };

const Message = ({ message }) => {
  const { text, username, date } = message || '';
  const { user } = useContext(AuthContext);

  // const GetTimeOfDay = () => {
  //   let time = new Date(date).getHours();

  //   const ampm =
  //     time >= 4 && time < 12
  //       ? timeOfDay.morning
  //       : time >= 12 && time <= 17
  //       ? timeOfDay.afternoon
  //       : time > 17 || time < 4
  //       ? timeOfDay.night
  //       : '';

  //   return `${moment(date).format('HH:mm')} ${ampm}`;
  // };

  return (
    <div
      className={
        (user && user.username === username) ||
        (user && user.pk === message.userId)
          ? 'chat-message sent'
          : 'chat-message received'
      }
    >
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        {user && user.username !== username ? (
          <MuiTypography value='h7'>{username}</MuiTypography>
        ) : null}

        <MuiTypography value='bodyXs'>
          {moment(date).format('HH:mm')}
        </MuiTypography>
      </Box>

      <MuiTypography value='bodySm'>{text}</MuiTypography>
    </div>
  );
};

export default Message;
