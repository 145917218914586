import { useNavigate } from 'react-router-dom';

import { Completed } from '../../../components/notifications/completed';

export const CompletedMeeting = ({ notification }) => {
  const navigate = useNavigate();
  const { title, meeting_id } = notification;
  const meetingTitle = `دورهمی ${title} ایجاد شد.`;

  return (
    <Completed
      title={meetingTitle}
      handleClick={() => navigate(`/meetings/details/${meeting_id}`)}
    />
  );
};
