import { useContext } from 'react';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';

import { Box } from '@mui/system';
import { LocationsContext } from '../../../../services/create-meeting/locations.context';
import { MuiTypography } from '../../../../components/typography/typography';

const text = {
  category: 'دسته بندی',
  restaurant: 'رستوران',
  cafe: 'کافه',
  whichCategory: 'چه دسته ای؟',
};

const categories = [{ restaurant: text.restaurant }, { cafe: text.cafe }];

export const Categories = ({ categoryState, setCategoryState }) => {
  const { setCategory } = useContext(LocationsContext);

  const items = categories.map((item) => {
    const itemValue = Object.keys(item)[0];
    const itemText = Object.values(item)[0];

    return (
      <MenuItem key={itemValue} value={itemValue}>
        {itemText}
      </MenuItem>
    );
  });

  const handleChange = (e) => {
    setCategory(e.target.value);
    setCategoryState(e.target.value);
  };

  return (
    <Box mt={4}>
      <MuiTypography value='h7'>{text.whichCategory}</MuiTypography>
      <FormControl className='categories' fullWidth>
        <InputLabel htmlFor='categories'>{text.category}</InputLabel>
        <Select
          value={categoryState}
          id='categories'
          label={text.category}
          onChange={handleChange}
        >
          {items}
        </Select>
      </FormControl>
    </Box>
  );
};
