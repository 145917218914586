import { useNavigate, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Fab, IconButton } from '@mui/material';

import { ReactComponent as AddContact } from '../../../../assets/icon/bottom-app-bar/outlined/message-outlined.svg';
import { ReactComponent as AddContactFilled } from '../../../../assets/icon/bottom-app-bar/filled/message-filled.svg';

import { ReactComponent as HomeFilled } from '../../../../assets/icon/bottom-app-bar/filled/home-filled.svg';
import { ReactComponent as SearchFilled } from '../../../../assets/icon/bottom-app-bar/filled/search-filled.svg';
import { ReactComponent as NotificationFilled } from '../../../../assets/icon/bottom-app-bar/filled/notification-filled.svg';

import { ReactComponent as HomeOutlined } from '../../../../assets/icon/bottom-app-bar/outlined/home-outlined.svg';
import { ReactComponent as SearchOutlined } from '../../../../assets/icon/search-outlined.svg';
import { ReactComponent as NotificationOutlined } from '../../../../assets/icon/bottom-app-bar/outlined/notification-outlined.svg';

import './index.scss';

const AppBar = ({ isHome }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let appBarItem = {
    home: {
      icon: pathname === '/meetings' ? <HomeFilled /> : <HomeOutlined />,
      route: '/meetings',
    },
    friends: {
      icon: pathname === '/friends' ? <AddContactFilled /> : <AddContact />,
      route: '/friends',
    },
    createMeeting: {
      icon: <AddIcon />,
      route: '/create-meeting',
    },
    search: {
      icon: pathname === '/search' ? <SearchFilled /> : <SearchOutlined />,
      route: '/search',
    },
    notifications: {
      icon:
        pathname === '/notifications' ? (
          <NotificationFilled />
        ) : (
          <NotificationOutlined />
        ),
      route: '/notifications',
    },
  };

  return (
    <div className={isHome ? 'app-bar home-app-bar' : 'app-bar'}>
      <div className='partition'>
        <IconButton
          onClick={() => navigate(appBarItem['home'].route)}
          className='app-bar-btn'
          color='inherit'
        >
          {appBarItem['home'].icon}
        </IconButton>
        <IconButton
          onClick={() => navigate(appBarItem['friends'].route)}
          className='app-bar-btn'
          color='inherit'
        >
          {appBarItem['friends'].icon}
        </IconButton>
      </div>

      {isHome && (
        <Fab
          color='secondary'
          aria-label='add'
          className='add-btn'
          onClick={() => {
            navigate(appBarItem['createMeeting'].route);
          }}
        >
          {appBarItem['createMeeting'].icon}
        </Fab>
      )}

      <div className='partition'>
        <IconButton
          onClick={() => navigate(appBarItem['search'].route)}
          color='inherit'
          className='app-bar-btn'
        >
          {appBarItem['search'].icon}
        </IconButton>
        <IconButton
          onClick={() => navigate(appBarItem['notifications'].route)}
          color='inherit'
          className='app-bar-btn'
        >
          {appBarItem['notifications'].icon}
        </IconButton>
      </div>
    </div>
  );
};

export default AppBar;
