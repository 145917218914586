export const alertText = {
  successfullySent: 'دعوت نامه شما با موفقیت ارسال  شد.',
  locationNotFound: 'متاسفانه موقعیت مکانیت پیدا نشد.',
  invitedUsersLengthError: 'برای ایجاد دورهمی، حداقل یک عضو لازمه.',
  tooManyRequestError: 'دسترسی شما برای ایجاد دورهمی تا ۲۴ ساعت محدود شده است.',
  meetingDeleted: 'دورهمی مورد نظر حذف شد.',
  limitationInDay:
    'دسترسی شما به علت درخواست بیش از حد مجاز تا یک شبانه روز آینده مسدود شد.',
  limitationInHour:
    'دسترسی شما به علت درخواست بیش از حد مجاز تا یک ساعت آینده مسدود شد.',
  limitationInMinute:
    'دسترسی شما به علت درخواست بیش از حد مجاز تا یک دقیقه آینده مسدود شد.',
  wrongCode: 'کد وارد شده اشتباه است.',
  usernameError:
    'نام کاربری باید حداقل شامل 4 کاراکتر از حروف انگلیسی،اعداد و کاراکتر خاص (_) باشد.',
};
