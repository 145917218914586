import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { text } from '../../../../texts/main-page';
import Detail from './detail';

const detailSource = [
  {
    backgroundSrc: process.env.PUBLIC_URL + '/images/main-page/meeting.png',
    iconsSrc: process.env.PUBLIC_URL + '/images/main-page/meeting-icons.png',
    text: text.friendlyGatheringDetail,
  },
  {
    backgroundSrc: process.env.PUBLIC_URL + '/images/main-page/guide.png',
    iconsSrc: process.env.PUBLIC_URL + '/images/main-page/guide-icons.png',
    text: text.guideDetail,
  },
  {
    backgroundSrc: process.env.PUBLIC_URL + '/images/main-page/event.png',
    iconsSrc: process.env.PUBLIC_URL + '/images/main-page/event-icons.png',
    text: text.eventsDetail,
  },
];

export default function DetailSwiper({ swiperRef, isMoved }) {
  return (
    <Swiper
      cssMode={true}
      className='detail-swiper'
      onSwiper={(swiper) => {
        swiperRef.current = swiper;
      }}
    >
      {detailSource.map(({ backgroundSrc, iconsSrc, text }, index) => (
        <SwiperSlide key={backgroundSrc}>
          {({ isActive, isVisible }) => (
            <Detail
              text={text}
              backgroundSrc={backgroundSrc}
              iconsSrc={iconsSrc}
              index={index}
              isActive={isActive}
              isMoved={isMoved}
            />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
