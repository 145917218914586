import { useContext, useEffect, useState } from 'react';

import './index.scss';
import { MeetingsContext } from '../../../services/meetings/meetings.context';
import { NotificationsContext } from '../../../services/notification/notifications.context';
import { dateConvertor } from '../../../services/meetings/meetings.services';
import { InvitationComponent } from '../../../components/notifications/invitation';

const InvitationMeeting = ({ notification, showMinimizeButton }) => {
  const [host, setHost] = useState(null);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const { usersInfo } = useContext(MeetingsContext);
  const { acceptRejectMeeting, minimizeNotification } =
    useContext(NotificationsContext);

  useEffect(() => {
    if (usersInfo && usersInfo.length > 0 && notification.sender_id) {
      setHost(
        usersInfo.find((user) => user.pk === parseInt(notification.sender_id))
      );
    } else {
    }
  }, [notification.sender_id, usersInfo]);

  let notificationText = `${host ? host.username : '....'} با موضوع ${
    notification.title
  } در تاریخ ${dateConvertor(
    notification.date
  )} پیشنهاد دورهمی داده، نظرت چیه؟`;

  return (
    <>
      {host ? (
        <InvitationComponent
          notificationText={notificationText}
          rejectMeeting={() =>
            acceptRejectMeeting(
              notification.notification_id,
              'reject',
              notification.meeting_id,
              setRejectLoading
            )
          }
          acceptMeeting={() =>
            acceptRejectMeeting(
              notification.notification_id,
              'accept',
              notification.meeting_id,
              setAcceptLoading
            )
          }
          showMinimizeButton={showMinimizeButton}
          minimize={() => minimizeNotification(notification.notification_id)}
          acceptLoading={acceptLoading}
          rejectLoading={rejectLoading}
        />
      ) : null}
    </>
  );
};

export default InvitationMeeting;
