import { useState, useContext, useEffect } from 'react';
import { Box } from '@mui/material';

import { CreateMeetingContext } from '../../../services/create-meeting/create-meeting.context';
import { CreateMeetingMap } from '../components/set-meeting/create-meeting-map';
import { DatePicker } from '../components/set-meeting/date-picker';
import { InvitedList } from '../components/add-friends/invited-list';
import { MeetingLocations } from './meeting-locations';
import { ReactComponent as Location } from '../../../assets/icon/location.svg';
import { ReactComponent as BackIcon } from '../../../assets/icon/back.svg';
import { MuiButton } from '../../../components/button';
import { CreateMeetingDialog } from '../components/set-meeting/create-meeting-dialog';
import { MuiInput } from '../../../components/input';
import { MuiTypography } from '../../../components/typography/typography';
import Alert from '../../../components/alert/index';
import { alertText } from '../../../texts/alert';
import { text } from '../../../texts/set-meeting';

export const SetMeeting = ({ setOpenCreateMeeting }) => {
  const { meeting, tooManyRequestError, setMeeting, setSelectedLocations } =
    useContext(CreateMeetingContext);

  const [openLocations, setOpenLocations] = useState(false);
  const [isExist, setIsExist] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [invitedUsersLengthError, setInvitedUsersLengthError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleClick = () => {
    setSelectedLocations(meeting.locations);

    if (openLocations) {
      setOpenLocations(false);
    } else {
      setOpenCreateMeeting(false);
    }
  };

  const setTitle = (e) => {
    let newMeeting = {
      ...meeting,
      title: e.target.value,
    };
    setMeeting(newMeeting);
  };

  useEffect(() => {
    const isMeetingValid =
      meeting.title !== '' &&
      meeting.date !== '' &&
      meeting.contacts.length > 0;

    setDisabled(!isMeetingValid);
  }, [meeting]);

  useEffect(() => {
    if (meeting.contacts.length > 0) {
      setInvitedUsersLengthError(false);
    } else {
      setInvitedUsersLengthError(true);
    }
  }, [meeting.contacts]);

  return (
    <div className='set-meeting-layout'>
      {openLocations ? (
        <MeetingLocations
          setOpenLocations={setOpenLocations}
          isExist={isExist}
          setIsExist={setIsExist}
        />
      ) : (
        <>
          <div className='create-meeting-container'>
            <Box mb={4}>
              <BackIcon
                cursor={'pointer'}
                id='back'
                onClick={() => {
                  handleClick();
                }}
              />
            </Box>
            {invitedUsersLengthError ? (
              <Alert
                text={alertText.invitedUsersLengthError}
                id='error'
                setCloseAlert={setInvitedUsersLengthError}
              />
            ) : null}

            {tooManyRequestError ? (
              <Alert
                text={alertText.tooManyRequestError}
                id='error'
                setCloseAlert={setInvitedUsersLengthError}
              />
            ) : null}

            <MuiInput
              label={text.title}
              value={meeting.title}
              onChange={setTitle}
              inputProps={{
                style: {
                  height: 40,
                  padding: '0 14px',
                },
              }}
              size='small'
              fullWidth
            />

            <DatePicker />

            <div className='invited-list'>
              <MuiTypography value='h7'>{text.friendsList}</MuiTypography>

              <InvitedList />
            </div>

            <div className='locations-selector'>
              <MuiButton
                variant='outlined'
                onClick={setOpenLocations}
                startIcon={<Location />}
                className='locations-selector-btn'
              >
                {text.locations}
              </MuiButton>
            </div>

            {meeting.locations.length > 0 && <CreateMeetingMap />}

            <div className='create-meeting-send-invitation'>
              <MuiButton
                onClick={() => setOpenDialog(true)}
                variant='contained'
                disabled={disabled}
                fullWidth
              >
                {text.sendInvitation}
              </MuiButton>
            </div>
          </div>
        </>
      )}

      <CreateMeetingDialog open={openDialog} setOpen={setOpenDialog} />
    </div>
  );
};
