import { useState } from 'react';

import { LocationsContextProvider } from '../../../services/create-meeting/locations.context';
import { CreateMeetingLayout } from '../../../components/layouts/create-meeting.layout';
import { AddFriends } from './add-friends';
import { SetMeeting } from './set-meeting';
import '../styles/add-friend.scss';
import '../styles/index.scss';
import '../styles/styles.scss';
import '../styles/locations.scss';
import '../styles/suggestion.scss';

export const Index = () => {
  const [openCreateMeeting, setOpenCreateMeeting] = useState(false);

  return (
    <div className='create-meeting-layout'>
      <CreateMeetingLayout>
        {!openCreateMeeting ? (
          <AddFriends setOpenCreateMeeting={setOpenCreateMeeting} />
        ) : (
          <LocationsContextProvider>
            <SetMeeting setOpenCreateMeeting={setOpenCreateMeeting} />
          </LocationsContextProvider>
        )}
      </CreateMeetingLayout>
    </div>
  );
};
