import { styled } from '@mui/system';

const AppContainer = styled('div')({
  maxWidth: 430,
  margin: '0 auto',
  padding: '32px 16px 16px 16px',
  background: '#fafafa',
  height: '100%',
});

export default AppContainer;
