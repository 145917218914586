import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvatarGroup } from '@mui/material';

import { MeetingsContext } from '../../../../services/meetings/meetings.context';
import { dateConvertor } from '../../../../services/meetings/meetings.services';
import { MeetingsCard } from '../../../../components/card';
import { isArray } from '../../../../utils/utility';
import CardAvatar from './users-avatar';

const Card = ({ children, meeting, meetingStatus }) => {
  const navigate = useNavigate();
  const { title, date_time, status } = meeting;
  const { usersInfo, getUserByUid } = useContext(MeetingsContext);

  const handleClick = () => {
    if (status === 0) {
      navigate(`/meetings/details/${meeting.id}`, {
        state: {
          data: meeting,
          usersInfoState: getUserByUid(meeting.members, usersInfo),
          showBottomAppBar: false,
        },
      });
    } else {
      navigate(`/vote/${meeting.id}`, {
        state: { meeting: meeting },
      });
    }
  };

  const users =
    usersInfo.length > 0 && getUserByUid(meeting.members, usersInfo);

  const usersComponent =
    isArray(users) &&
    users.map((user, i) => <CardAvatar user={user} key={`${user.uid}-${i}`} />);

  const avatars = (
    <AvatarGroup
      className={
        users.length >= 6 ? 'avatar-group overflow-group' : 'avatar-group'
      }
      max={6}
    >
      {usersComponent}
    </AvatarGroup>
  );

  return (
    <MeetingsCard
      handleClick={handleClick}
      title={title}
      date_time={date_time && dateConvertor(date_time)}
      status={meetingStatus}
      avatars={avatars}
    >
      {children}
    </MeetingsCard>
  );
};

export default Card;
