import { useContext } from 'react';
import Dialog from '@mui/material/Dialog';

import { CreateMeetingContext } from '../../../../services/create-meeting/create-meeting.context';
import { MuiButton } from '../../../../components/button';
import { MuiTypography } from '../../../../components/typography/typography';

const text = {
  title: 'از ارسال دعوت نامه مطمئنی؟',
  cancel: 'نه',
  confirm: 'آره مطمئنم',
};

export const CreateMeetingDialog = ({ open, setOpen }) => {
  const { createMeeting, isLoading } = useContext(CreateMeetingContext);

  return (
    <Dialog
      open={open}
      onClose={() => (isLoading ? setOpen(true) : setOpen(false))}
    >
      <div className='create-meeting-dialog-content'>
        <MuiTypography value='h7'>{text.title}</MuiTypography>

        <div className='create-meeting-dialog-action'>
          <MuiButton
            onClick={() => setOpen(false)}
            className='cancel-btn'
            variant='outlined'
            disabled={isLoading}
            fullWidth
          >
            {text.cancel}
          </MuiButton>

          <MuiButton
            onClick={createMeeting}
            variant='contained'
            className='confirm-btn'
            loading={isLoading}
            fullWidth
          >
            {text.confirm}
          </MuiButton>
        </div>
      </div>
    </Dialog>
  );
};
