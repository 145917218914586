import { motion } from 'framer-motion';

import DetailSwiper from './swiper';

const DetailContainer = ({
  swiperRef,
  showFeatures,
  isMoved,
  isLargeScreen,
}) => {
  const opacity = isLargeScreen ? (showFeatures ? 1 : 0) : 1;
  return (
    <motion.div
      animate={{ opacity }}
      transition={{ duration: 0.2 }}
      className='features-details-container'
    >
      {isLargeScreen ? <div className='features-details-wrapper1' /> : null}

      <div className='features-details-wrapper2'>
        <DetailSwiper swiperRef={swiperRef} isMoved={isMoved} />
      </div>
    </motion.div>
  );
};

export default DetailContainer;
