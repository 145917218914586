import { useContext, useState, useRef, useEffect } from 'react';
import { Box, Divider, IconButton, InputAdornment, Stack } from '@mui/material';

import { SuggestionItem } from './suggestion-item';
import { AuthContext } from '../../../../services/authentication/auth.context';
import { isArray } from '../../../../utils/utility';
import { ReactComponent as SearchIcon } from '../../../../assets/icon/search-outlined.svg';
import { CreateMeetingContext } from '../../../../services/create-meeting/create-meeting.context';
import { MuiInput } from '../../../../components/input';
import { MuiTypography } from '../../../../components/typography/typography';

const text = {
  search: 'جستجو ...',
  quickAdd: 'اضافه کردن سریع',
};

const getWindowSize = () => {
  const { innerHeight } = window;
  return innerHeight;
};

export const Suggestion = () => {
  const { friends } = useContext(AuthContext);
  const { meeting } = useContext(CreateMeetingContext);
  const [searchTerm, setSearchTerm] = useState('');

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [defaultHeight, setDefaultHeight] = useState(268);
  const [isAddedHeight, setIsAddedHeight] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (meeting.contacts.length === 0) {
      setDefaultHeight(268);
      setIsAddedHeight(false);
    }
  }, [defaultHeight, meeting, windowSize]);

  const suggestionList =
    isArray(friends) &&
    friends
      .filter((friend) => {
        let value = searchTerm && searchTerm.toLowerCase();
        let isExist = friend.username.includes(value);
        let result = '';

        if (value) {
          if (isExist) {
            result = value;
          }
        } else {
          result = friend;
        }

        return result;
      })
      .slice(0, 8)
      .map((item, i) => (
        <SuggestionItem
          key={i}
          friend={item}
          defaultHeight={defaultHeight}
          setDefaultHeight={setDefaultHeight}
          isAddedHeight={isAddedHeight}
          setIsAddedHeight={setIsAddedHeight}
        />
      ));

  return (
    <div>
      {friends.length > 0 ? (
        <MuiTypography value='buttonLg'>
          {text.quickAdd}
        </MuiTypography>
      ) : null}

      <Box my={2}>
        <MuiInput
          onChange={(e) => setSearchTerm(e.target.value)}
          className='search-suggestion'
          placeholder={text.search}
          fullWidth
          inputRef={ref}
          size='small'
          InputProps={{
            style: {
              height: 40,
            },
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton edge='end' onClick={() => ref.current.focus()}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Stack
        className='suggestion-list'
        divider={<Divider className='divider' flexItem />}
        // style={{ height: 'max-content', maxHeight: height }}
      >
        {suggestionList}
      </Stack>
    </div>
  );
};
