import { styled, TextField } from '@mui/material';
import { theme } from '../theme/index';

export const MuiInput = styled(TextField)({
  height: theme.sizes[4],
  background: theme.colors.white,

  div: {
    borderRadius: theme.sizes[0],
  },

  input: {
    fontSize: theme.fontSizes.input,
    fontWeight: theme.fontWeights.medium,
    height: 22,
  },

  label: {
    fontWeight: 500,
  },
});

export const MuiTextArea = styled(TextField)({
  background: theme.colors.white,

  div: {
    borderRadius: theme.sizes[0],
  },

  input: {
    fontSize: theme.fontSizes.input,
    fontWeight: theme.fontWeights.medium,
  },

  label: {
    fontWeight: 500,
  },
});
