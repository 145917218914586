import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import UserAvatar from '../user-avatar';
import { MuiTypography } from '../typography/typography';
import { AuthContext } from '../../services/authentication/auth.context';

export const UserInformation = () => {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/profile');
  };

  return (
    <span className='user-info' onClick={handleClick}>
      <UserAvatar user={user} className='user-avatar' />

      <MuiTypography value='h6' className='username'>
        {user && user.username}
      </MuiTypography>
    </span>
  );
};
