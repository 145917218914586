import { Avatar } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import useImageLoaded from '../../../../hook/useImageLoaded';
import { ProfileAvatarSkeleton } from '../../../../components/skeleton/skeleton';

const UsersAvatar = ({ user }) => {
  const { isLoading } = useImageLoaded(user);

  const UserAvatar = () => {
    if (isLoading) {
      return <ProfileAvatarSkeleton className='mt-dtl-users-avatar' />;
    } else if (user.avatar) {
      return <Avatar className='avatar' src={user.avatar} />;
    } else {
      return (
        <Avatar className='avatar'>
          <AccountCircleIcon />
        </Avatar>
      );
    }
  };

  return <UserAvatar />;
};

export default UsersAvatar;
