import { useEffect, useState, useContext } from 'react';
import { Fab } from '@mui/material';

import { CreateMeetingContext } from '../../../../services/create-meeting/create-meeting.context';
import { ReactComponent as AddContact } from '../../../../assets/icon/add-contact.svg';
import { ReactComponent as PhoneContactIcon } from '../../../../assets/icon/phone-contact.svg';
import { ReactComponent as AddPhoneNumber } from '../../../../assets/icon/add-phone-number.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icon/close.svg';
import { MuiButton } from '../../../../components/button';
import { AddPhoneNumberDialog } from './add-phone-number-dialog';

const text = {
  invitePhoneContact: 'دعوت از مخاطبین تلفن',
  inviteWithPhoneNumber: 'دعوت با شماره تلفن',
  invitationLimit: 'در حال حاظر فقط ۱۰ نفر تا از دوستات رو میتونی دعوت کنی',
};

export const ContactPicker = ({ open, setOpen }) => {
  const [isSupport, setIsSupport] = useState(true);

  const { invitationLimit, openContactPicker, setOpenDialog } =
    useContext(CreateMeetingContext);

  useEffect(() => {
    if ('contacts' in navigator && 'ContactsManager' in window) {
      setIsSupport(true);
    } else {
      setIsSupport(false);
    }
  }, []);

  return (
    <div className='contact-picker'>
      {open === 'none' ? (
        <Fab
          color='primary'
          onClick={() => setOpen(open === 'none' ? 'block' : 'none')}
          className='open-contact-picker-fab'
        >
          <AddContact />
        </Fab>
      ) : (
        <Fab
          color='primary'
          onClick={() => setOpen(open === 'none' ? 'block' : 'none')}
          className='open-contact-picker-fab'
        >
          <CloseIcon className='close-contact-picker-menu' />
        </Fab>
      )}

      <div className='contact-picker-container' style={{ display: open }}>
        {isSupport ? (
          <div className='contact-picker-item'>
            <Fab
              onClick={() =>
                invitationLimit
                  ? alert(text.invitationLimit)
                  : openContactPicker(text, isSupport)
              }
              className='contact-fab-icon'
            >
              <PhoneContactIcon />
            </Fab>

            <MuiButton
              onClick={() =>
                invitationLimit
                  ? alert(text.invitationLimit)
                  : openContactPicker(text, isSupport)
              }
              variant='contained'
              color='button'
              className='invite-phone-btn'
            >
              {text.invitePhoneContact}
            </MuiButton>
          </div>
        ) : null}

        <div className='contact-picker-item'>
          <Fab
            onClick={() =>
              invitationLimit
                ? alert(text.invitationLimit)
                : setOpenDialog(true)
            }
            className='contact-fab-icon'
          >
            <AddPhoneNumber />
          </Fab>

          <MuiButton
            onClick={() =>
              invitationLimit
                ? alert(text.invitationLimit)
                : setOpenDialog(true)
            }
            variant='contained'
            color='button'
            className='invite-phone-btn'
          >
            {text.inviteWithPhoneNumber}
          </MuiButton>
        </div>
      </div>

      <AddPhoneNumberDialog />
    </div>
  );
};
