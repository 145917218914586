import { useContext, useState } from 'react';
import { Box, InputAdornment } from '@mui/material';
import axios from 'axios';

import './index.scss';
import { urlConfig } from '../../../../utils/env';
import { AuthContext } from '../../../../services/authentication/auth.context';
import { handleValidation } from '../../../../global-functions/global-functions';
import { MuiButton } from '../../../../components/button';
import { MuiInput } from '../../../../components/input';
import { MuiTypography } from '../../../../components/typography/typography';
import Alert from '../../../../components/alert/';

const text = {
  title1: 'ورود به میزبان',
  title2: 'برای ورود و یا ثبت نام در میزبان، شماره موبایل خود را وارد کنید.',
  inputLabel: 'شماره موبایل',
  submitBtn: 'ارسال کد تایید',
  blockErrInHour:
    'دسترسی شما به علت درخواست بیش از حد مجاز تا یک ساعت آینده مسدود شد.',
  blockErrInMinute:
    'دسترسی شما به علت درخواست بیش از حد مجاز تا یک دقیقه آینده مسدود شد.',
  privacy: (
    <MuiTypography value='captionMd'>
      با ثبت نام در میزبان، <span>شرایط و قوانین</span> را قبول میکنم.
    </MuiTypography>
  ),
};

export const Login = () => {
  const { mobilePhone, setMobilePhone, setIsEnterMobilePhoneByUser } =
    useContext(AuthContext);

  const [value, setValue] = useState(mobilePhone);
  const [isLoading, setIsLoading] = useState(false);
  const [blockErrInMinute, setBlockErrInMinute] = useState(false);
  const [blockErrInHour, setBlockErrInHour] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    if (
      !isNaN(value) &&
      value.length <= 10 &&
      (value.length === 0 || value[0] !== '0')
    ) {
      setValue(value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && handleValidation(value)) {
      handleClick();
    }
  };

  const handleClick = () => {
    setMobilePhone(value);
    setIsLoading(true);

    axios({
      method: 'post',
      url: urlConfig.login,
      // headers: { Authorization: urlConfig.authorization },
      data: { phonenumber: `+98${value}` },
    })
      .then(() => {
        setIsLoading(false);
        setIsEnterMobilePhoneByUser(true);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.status === 429) {
          if (
            'status' in error.response.data &&
            error.response.data.status === 0
          ) {
            setBlockErrInMinute(true);
            setBlockErrInHour(false);
          } else if (error.response.data.status === 1) {
            setBlockErrInHour(true);
            setBlockErrInMinute(false);
          }
        }
      });
  };

  return (
    <Box className='login'>
      {blockErrInMinute ? (
        <Alert
          text={text.blockErrInMinute}
          id='error'
          setCloseAlert={setBlockErrInMinute}
        />
      ) : null}

      {blockErrInHour ? (
        <Alert
          text={text.blockErrInHour}
          id='error'
          setCloseAlert={setBlockErrInHour}
        />
      ) : null}

      <MuiTypography component='h1' value='h6'>
        {text.title1}
      </MuiTypography>
      <MuiTypography value='bodyMd'>{text.title2}</MuiTypography>

      <MuiInput
        value={value}
        onChange={handleChange}
        label={text.inputLabel}
        inputProps={{ maxLength: 10 }}
        onKeyDown={handleKeyDown}
        variant='outlined'
        placeholder='9123456789'
        autoComplete='phone'
        type='tel'
        size='small'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start' className='inputAdornment'>
              + 98
            </InputAdornment>
          ),
        }}
        className={
          value.toString().length > 0
            ? 'phone-textfield'
            : 'phone-textfield empty-input'
        }
        id='phone-number'
      />

      {text.privacy}

      <MuiButton
        variant='contained'
        onClick={handleClick}
        className={!handleValidation(value) ? 'button-disabled' : ''}
        disabled={!handleValidation(value)}
        loading={isLoading}
        id='send-btn'
        fullWidth
      >
        {text.submitBtn}
      </MuiButton>
    </Box>
  );
};
