import AppBar from '../../features/meetings/components/appBar';

export const HomeLayout = ({ children, isHome }) => {
  return (
    <>
      {children}
      <AppBar isHome={isHome} />
    </>
  );
};
