import { colors } from './colors';
import { sizes, sizesVariant } from './sizes';
import { fonts, fontWeights, fontSizes } from './fonts';
import { lineHeights, space } from './spacing';

export const theme = {
  colors,
  sizes,
  sizesVariant,
  fonts,
  fontWeights,
  fontSizes,
  lineHeights,
  space,
};
