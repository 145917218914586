import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import { text } from '../../../../texts/main-page';
import { MuiTypography } from '../../../../components/typography/typography';
import { MuiButton } from '../../../../components/button';

const HeaderTitle = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  return (
    <div className='header-title-container'>
      <div className='header-text'>
        <MuiTypography
          value={isSmallScreen ? 'h5' : 'h1'}
          id='header-text-title'
        >
          {text.title}
        </MuiTypography>

        <MuiTypography
          value={isSmallScreen ? 'bodyMd' : 'h2'}
          id='header-text-subtitle'
        >
          {text.subtitle}
        </MuiTypography>
      </div>

      <div className='header-buttons'>
        <MuiButton
          onClick={() => navigate('login')}
          variant='contained'
          className='login-btn'
          fullWidth={isSmallScreen ? true : false}
        >
          {text.login}
        </MuiButton>

        <MuiButton
          variant='outlined'
          className='outlined-btn'
          fullWidth={isSmallScreen ? true : false}
        >
          {text.video}
        </MuiButton>
      </div>
    </div>
  );
};

export default HeaderTitle;
