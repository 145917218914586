import { useContext } from 'react';
import { Divider, Stack } from '@mui/material';

import '../../../styles/locations.scss';
import { LocationItem } from './location-item';
import { isArray } from '../../../../../utils/utility';
import { LocationsContext } from '../../../../../services/create-meeting/locations.context';

const Locations = ({ searchTerm }) => {
  const { locations } = useContext(LocationsContext);

  const Items =
    isArray(locations) &&
    locations.map((location, i) => (
      <LocationItem location={location} key={i} />
    ));

  const SearchList =
    isArray(locations) &&
    locations
      .filter((location) => {
        let value = searchTerm && searchTerm.toLowerCase();
        let isExist = location.name.includes(value);
        return isExist && value;
      })
      .slice(0, 8)
      .map((item, i) => <LocationItem location={item} key={i} />);

  return (
    <>
      {isArray(locations) && (
        <Stack
          className='location-list'
          divider={<Divider className='divider' flexItem />}
        >
          {searchTerm ? SearchList : Items}
        </Stack>
      )}
    </>
  );
};

export default Locations;
