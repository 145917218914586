import { useNavigate } from 'react-router-dom';

import './styles.scss';
import PageNotFound from './page-not-found';
import ForbiddenError from './forbidden-error';
import { ReactComponent as BackIcon } from '../../assets/icon/back.svg';

const ErrorPage = ({ status }) => {
  const navigate = useNavigate();

  const component = {
    forbiddenError: <ForbiddenError />,
    pageNotFound: <PageNotFound />,
  };

  return (
    <>
      <BackIcon
        cursor={'pointer'}
        id='back'
        onClick={() => navigate('/meetings', { replace: true })}
      />

      <div className='error-box'>
        {status ? component[status] : <PageNotFound />}
      </div>
    </>
  );
};

export default ErrorPage;
