import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';

import { MeetingsContextProvider } from '../../services/meetings/meetings.context';
import { AuthContext } from '../../services/authentication/auth.context';
import { NotificationsContextProvider } from '../../services/notification/notifications.context';
import { removeCookies } from '../../utils/fetch-request';
import AppContainer from '../app-container';

export const AppLayout = () => {
  const { user, isAuthenticated } = useContext(AuthContext);

  if (!isAuthenticated) {
    return <Navigate to='/' replace />;
  }

  if (isAuthenticated && user && !user.username) {
    return <Navigate to='/register' replace />;
  }

  if (!Cookies.get('_mzb_token')) {
    removeCookies();
  }

  return (
    <AppContainer>
      <MeetingsContextProvider>
        <NotificationsContextProvider>
          <Outlet />
        </NotificationsContextProvider>
      </MeetingsContextProvider>
    </AppContainer>
  );
};
