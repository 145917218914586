import { useContext, useRef, useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { Box } from '@mui/system';

import { CreateMeetingContext } from '../../../services/create-meeting/create-meeting.context';
import Locations from '../components/set-meeting/Locations/index';
import { RequestPermission } from '../components/set-meeting/request-permission';
import { SearchLocations } from './search-locations';
import { LocationsContext } from '../../../services/create-meeting/locations.context';
import { ReactComponent as ArrowDown } from '../../../assets/icon/arrow-down.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icon/filter.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icon/search-outlined.svg';
import { MuiButton } from '../../../components/button';
import { ReactComponent as BackIcon } from '../../../assets/icon/back.svg';
import { MuiInput } from '../../../components/input';
import { MuiTypography } from '../../../components/typography/typography';

const text = {
  found: 'پیدا شد',
  confirm: 'تایید',
  mostPopular: 'محبوب ترین',
  search: 'جستجو ...',
};

const categories = {
  restaurant: 'رستوران',
  cafe: 'کافه',
};

export const MeetingLocations = ({ setOpenLocations, isExist, setIsExist }) => {
  const { addLocation } = useContext(CreateMeetingContext);
  const { locations, category } = useContext(LocationsContext);

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);

  return (
    <>
      {open ? (
        <SearchLocations setOpen={setOpen} />
      ) : (
        <div className='meeting-locations'>
          <Box mb={4}>
            <BackIcon
              cursor={'pointer'}
              id='back'
              onClick={() => {
                setOpenLocations(false);
              }}
            />
          </Box>

          {!isExist && (
            <RequestPermission
              setIsLoading={setIsLoading}
              setIsExist={setIsExist}
            />
          )}

          <MuiInput
            size='small'
            className='search-suggestion'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={text.search}
            inputRef={ref}
            InputProps={{
              style: {
                height: 40,
              },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton edge='end' onClick={() => ref.current.focus()}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />

          <Box display='flex' alignItems='center'>
            <Box flexGrow={1}>
              {locations && locations.length > 0 && (
                <MuiTypography value='bodySm'>
                  {locations.length} {categories[category]} {text.found}.
                </MuiTypography>
              )}
            </Box>

            <Box my={2} display='flex'>
              <div
                className='filter-btn'
                onClick={() => {
                  setOpen(true);
                  setSearchTerm('');
                }}
              >
                <FilterIcon />
              </div>

              <div className='most-popular-btn'>
                <MuiTypography value='bodyXs'>{text.mostPopular}</MuiTypography>
                <ArrowDown />
              </div>
            </Box>
          </Box>
          <div className='locations'>
            {isLoading ? (
              <MuiTypography value='bodySm'>
                درحال بارگذاری...
              </MuiTypography>
            ) : (
              <Locations searchTerm={searchTerm} />
            )}
          </div>

          <div className='create-meeting-send-invitation'>
            <MuiButton
              onClick={() => {
                addLocation();
                setOpenLocations(false);
              }}
              variant='contained'
              fullWidth
            >
              {text.confirm}
            </MuiButton>
          </div>
        </div>
      )}
    </>
  );
};
