import { useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Alert, Box, IconButton, InputAdornment } from '@mui/material';

import '../../styles/add-phone-number-dialog.scss';
import { MuiButton } from '../../../../components/button';
import { MuiInput } from '../../../../components/input';
import { InvitedList } from './invited-list';
import { ReactComponent as CloseIcon } from '../../../../assets/icon/close-circle.svg';
import { handleValidation } from '../../../../global-functions/global-functions';
import { CreateMeetingContext } from '../../../../services/create-meeting/create-meeting.context';
import { AuthContext } from '../../../../services/authentication/auth.context';

const text = {
  addPhoneNumber: 'شماره تلفن دوست خود را برای دعوت به دورهمی وارد کنید.',
  confirm: 'تایید',
  phoneNumber: 'شماره تلفن',
  validationErr: 'شماره تلفن صحیح وارد کنید',
  alreadyExist: 'این شماره قبلا وارد شده است',
  hostErr: 'نیازی به وارد کردن شماره تلفن خودتان نیست',
  invitationLimit: 'در حال حاظر فقط ۱۰ نفر تا از دوستات رو میتونی دعوت کنی',
};

export const AddPhoneNumberDialog = () => {
  const { user } = useContext(AuthContext);
  const {
    meeting,
    invitationLimit,
    updateContacts,
    openDialog,
    setOpenDialog,
    setIsExist,
  } = useContext(CreateMeetingContext);

  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
    setValue('');
    setErrorText('');
    setError(false);
    setIsExist(meeting.contacts.length > 0);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (
      !isNaN(value) &&
      value.length <= 10 &&
      (value.length === 0 || value[0] !== '0')
    ) {
      setValue(value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 188) {
      e.preventDefault();
      addPhoneNumber();
    }
  };

  const addPhoneNumber = (setIsExist) => {
    const phone = `+98${value}`;

    if (invitationLimit) {
      alert(text.invitationLimit);
    } else {
      if (!value && meeting.contacts.length > 0) {
        handleClose();
      } else if (handleValidation(value)) {
        const isExist = meeting.contacts.some((i) => i.phoneNumber === phone);

        if (user && user.mobilePhone === phone) {
          setError(true);
          setErrorText(text.hostErr);
        } else if (isExist) {
          setError(true);
          setErrorText(text.alreadyExist);
        } else {
          updateContacts(phone, null, null);
          setValue('');
          setError(false);
          if (setIsExist) {
            setIsExist(true);
            setOpenDialog(false);
            setValue('');
            setErrorText('');
            setError(false);
          }
        }
      } else {
        setError(true);
        setErrorText(text.validationErr);
      }
    }
  };

  useEffect(() => {
    if (value) {
      if (handleValidation(value)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else if (meeting.contacts.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [meeting.contacts.length, value]);

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      className='add-phone-number-dialog'
    >
      <IconButton
        onClick={handleClose}
        className='close-dialog-icon'
        aria-label='close'
      >
        <CloseIcon />
      </IconButton>

      <div className='add-phone-number-dialog-content'>
        <span className='add-phone-number-title'>{text.addPhoneNumber}</span>

        {error ? <Alert severity='error'>{errorText}</Alert> : null}

        <Box mt={2} mb={meeting.contacts.length > 0 ? 2 : 3}>
          <MuiInput
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            variant='standard'
            label={text.phoneNumber}
            placeholder='9123456789'
            autoComplete='phone'
            type='tel'
            inputProps={{ maxLength: 10 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>+ 98</InputAdornment>
              ),
            }}
            className='phone-number'
            size='small'
            fullWidth
          />
        </Box>

        {meeting.contacts.length > 0 ? <InvitedList /> : null}

        <MuiButton
          disabled={disabled}
          onClick={() => addPhoneNumber(setIsExist)}
          variant='outlined'
        >
          {text.confirm}
        </MuiButton>
      </div>
    </Dialog>
  );
};
