import { MuiButton } from '../button';
import { MuiTypography } from '../typography/typography';

const text = {
  details: 'مشاهده جزئیات',
};

export const Completed = ({ title, handleClick }) => {
  return (
    <div className='completed-notification'>
      <MuiTypography value='bodyMd'>{title}</MuiTypography>

      <MuiButton variant='text' onClick={() => handleClick()}>
        {text.details}
      </MuiButton>
    </div>
  );
};
