import { Grid } from '@mui/material';

import '../../styles/header/header.scss';
import '../../styles/header/header-lg-screen.scss';
import '../../styles/header/header-sm-screen.scss';
import HeaderTitle from './header-title';

const Header = () => {
  return (
    <header className='header-content'>
      <Grid container className='header-container'>
        <Grid item xs={12} md={6} className='header-title'>
          <HeaderTitle />
        </Grid>

        <Grid item xs={12} md={6} className='header-image'>
          <img src={process.env.PUBLIC_URL + '/images/main-page/header-map.png'} alt='map' />
        </Grid>
      </Grid>
    </header>
  );
};

export default Header;
