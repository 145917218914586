import axios from 'axios';
import Cookies from 'js-cookie';

import { urlConfig } from './env';

export const request = (url, method, data, access) => {
  const refresh = Cookies.get('_mzb_token');

  if (!refresh) {
    removeCookies();
    return;
  }

  return axios({
    url,
    method,
    data: method !== 'get' ? data : null,
    headers: {
      Authorization: `Bearer ${access ? access : refresh}`,
    },
  });
};

export const logout = (setIsLoading) => {
  const refresh = Cookies.get('_mzb_token');
  setIsLoading(true);

  if (refresh) {
    axios({
      url: urlConfig.logout,
      method: 'post',
      data: {
        refresh,
      },
    })
      .then(() => removeCookies())
      .catch((e) => {
        console.log(e)
        removeCookies()
      });
  } else {
    removeCookies();
  }
};

export const removeCookies = () => {
  Object.keys(Cookies.get()).forEach((cookieName) => {
    Cookies.remove(cookieName, {
      secure: true,
      sameSite: 'strict',
    });
  });
  return window.location.reload();
};

export const isTokenExpired = (token) => {
  // Check the token expiration time against the current time
  const tokenExpirationTime = getTokenExpirationTime(token);
  const currentTime = Date.now() / 1000; // Convert to seconds
  return currentTime >= tokenExpirationTime;
};

const getTokenExpirationTime = (token) => {
  // Parse the access token and extract the expiration time
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload.exp;
};

export const getAccessToken = async (
  url,
  method,
  data,
  setIsLoading,
  setAccessToken
) => {
  const refresh = Cookies.get('_mzb_token');

  try {
    setAccessToken('');
    const response = await request(urlConfig.refresh, 'post', {
      refresh: refresh,
    });

    if (response.data && response.data.access) {
      // Update the access token state with the new token
      setAccessToken(response.data.access);
      return url ? request(url, method, data, response.data.access) : null;
    }
  } catch (e) {
    console.error('Error refreshing access token:', e);
    setIsLoading && setIsLoading(false);

    removeUser(e);
  }
};

export const removeUser = (e) => {
  if (e.response && e.response.status === 401) {
    removeCookies();
  }
};
