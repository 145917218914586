import { Box, Grid } from '@mui/material';

import '../../styles/swiper/swiper-sm-screen.scss';
import '../../styles/swiper/swiper-lg-screen.scss';
import { ReactComponent as Ellipse } from '../../../../assets/icon/ellipse.svg';
import { MuiTypography } from '../../../../components/typography/typography';

const Detail = ({
  backgroundSrc,
  iconsSrc,
  text,
  index,
  isActive,
  isMoved,
}) => {
  return (
    <Grid container className='detail-wrapper'>
      <Grid item lg={6} className='detail-image-container'>
        <img src={backgroundSrc} alt='detail' className='detail-image' />
        <img
          src={iconsSrc}
          alt='detail icons'
          className={
            isMoved && isActive ? `icons icons-${index}` : 'icons-none'
          }
        />
        <Box
          className={
            isMoved && isActive ? `bg-shadow bg-shadow-${index}` : null
          }
        />
      </Grid>

      <Grid item lg={5} className='detail-text'>
        <Box mb={3}>
          <MuiTypography value='h6' id={index}>
            {text.heading}
          </MuiTypography>
        </Box>

        {Object.values(text.titles).map((title) => (
          <Box mb={2} display='flex' key={title}>
            <Box display='flex' alignItems='center'>
              <Ellipse className={`svg-${index}`} />

              <Box ml={1}>
                <MuiTypography value='bodyMd'>{title}</MuiTypography>
              </Box>
            </Box>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default Detail;
