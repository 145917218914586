import { IconButton } from '@mui/material';

import { ReactComponent as CloseIcon } from '../../assets/icon/close-square.svg';
import { MuiButton } from '../button';
import { MuiTypography } from '../typography/typography';

const actions = ['نیستم', 'اتاق گفتگو'];

export const InvitationComponent = ({
  notificationText,
  rejectMeeting,
  acceptMeeting,
  showMinimizeButton,
  minimize,
  rejectLoading,
  acceptLoading,
}) => {
  return (
    <div>
      <div className='notification-message'>
        <MuiTypography value='bodyMd'>{notificationText}</MuiTypography>

        {showMinimizeButton ? (
          <IconButton className='close-button' onClick={minimize}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>

      <MuiButton
        onClick={rejectMeeting}
        loading={rejectLoading}
        disabled={acceptLoading}
        variant='outlined'
        className='reject-meeting-btn'
      >
        {actions[0]}
      </MuiButton>

      <MuiButton
        onClick={acceptMeeting}
        loading={acceptLoading}
        disabled={rejectLoading}
        variant='contained'
      >
        {actions[1]}
      </MuiButton>
    </div>
  );
};
