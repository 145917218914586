import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';

import { LocationsContext } from '../../../../services/create-meeting/locations.context';
import { MuiButton } from '../../../../components/button';
import {
  getLocations,
  getUserLocation,
} from '../../../../services/create-meeting/locations.services';

const text = {
  requestPermission:
    'برای اینکه بتونیم پیشنهادهای مناسب تری رو برات نمایش بدیم،لازمه دسترسی به موقعیت مکانی رو فعال کنی.',
  active: 'فعال سازی',
  cancel: 'فعلا نه',
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const RequestPermission = ({ setIsLoading, setIsExist }) => {
  const [open, setOpen] = useState(false);
  const { city, setCity, setLocations } = useContext(LocationsContext);

  const checkLocationPermissionStatus = () => {
    navigator.permissions &&
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((PermissionStatus) => {
          if (PermissionStatus.state === 'granted') {
            getUserLocation(city, setCity, setOpen, setLocations, setIsLoading);
            setIsExist(true);
          } else if (PermissionStatus.state === 'prompt') {
            setOpen(true);
          } else {
            setOpen(false);
            getLocations(city, 'restaurant', setIsLoading, setLocations, null);
            setIsExist(true);
          }
        });
  };

  useEffect(() => {
    checkLocationPermissionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      className='request-permission-container'
    >
      <DialogContent className='request-permission-content'>
        <span className='request-permission-text'>
          {text.requestPermission}
        </span>
      </DialogContent>

      <DialogActions className='request-permission-actions'>
        <MuiButton
          variant='outlined'
          onClick={() => {
            setOpen(false);
            getLocations(city, 'restaurant', setIsLoading, setLocations, null);
          }}
          fullWidth
        >
          {text.cancel}
        </MuiButton>
        <MuiButton
          variant='contained'
          onClick={() =>
            getUserLocation(city, setCity, setOpen, setLocations, setIsLoading)
          }
          fullWidth
        >
          {text.active}
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};
