import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';
import { theme } from '../theme/index';

export const MuiButton = styled(LoadingButton)(({ loading, ...props }) => ({
  borderRadius: theme.sizes[0],
  height: theme.sizes[4],
  fontWeight: theme.fontWeights.bold,
  fontSize: theme.fontSizes.body,
  boxShadow: 'none',

  '&:disabled': {
    border: `1px solid ${theme.colors.gray3}`,
    color: theme.colors.gray3,
    backgroundColor: theme.colors.white,
  },

  ...(loading && {
    color: 'transparent !important',
  }),

  ...(props.variant === 'outlined' && {
    border: `1px solid ${theme.colors.primary}`,
  }),

  span: {
    color: theme.colors.gray3,
  },
  input: {
    height: 23,
  },
}));
