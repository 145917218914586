import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';

import { AuthProvider } from './services/authentication/auth.context';
import { muiTheme } from './theme/muiTheme';
import { AppLayout } from './components/layouts/app.layout';
import { AuthLayout } from './components/layouts/auth.layout';
import { RegisterLayout } from './components/layouts/register.layout';
import { HomeLayout } from './components/layouts/home.layout';
import { Meetings } from './features/meetings/pages/meetings';
import { Index } from './features/authentication/pages';
import { Index as CreateMeeting } from './features/create-meeting/pages/index';
import { FriendsList } from './features/friends-list/pages/friends-list';
import { Notifications } from './features/notifications/pages/notifications';
import { MeetingDetails } from './features/meeting-details/pages/meeting-details';
import { Register } from './features/authentication/components/Register';
import { Profile } from './features/profile/pages/profile';
import Voting from './features/voting/pages';
import Search from './features/search/index';
import ErrorPage from './components/error/error-page';
import AppContainer from './components/app-container';
import Main from './features/main-page/pages/main';

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const App = () => {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={muiTheme}>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<AuthLayout />}>
                <Route index element={<Main />} />
              </Route>

              <Route path='/login' element={<AuthLayout />}>
                <Route
                  index
                  element={
                    <AppContainer>
                      <Index />
                    </AppContainer>
                  }
                />
              </Route>

              <Route path='/register' element={<RegisterLayout />}>
                <Route index element={<Register />} />
              </Route>

              <Route path='/' element={<AppLayout />}>
                <Route
                  index
                  path='meetings'
                  element={
                    <HomeLayout isHome>
                      <Meetings />
                    </HomeLayout>
                  }
                />

                <Route path='create-meeting' element={<CreateMeeting />} />
                <Route path='vote/:id' element={<Voting />} />

                <Route
                  path='friends'
                  element={
                    <HomeLayout isHome>
                      <FriendsList />
                    </HomeLayout>
                  }
                />
                <Route path='profile' element={<Profile />} />

                <Route
                  path='search'
                  element={
                    <HomeLayout isHome>
                      <Search />
                    </HomeLayout>
                  }
                />

                <Route
                  path='notifications'
                  element={
                    <HomeLayout isHome>
                      <Notifications />
                    </HomeLayout>
                  }
                />
                <Route
                  path='meetings/details/:id'
                  element={<MeetingDetails />}
                />
              </Route>

              <Route path='*' element={<ErrorPage status='pageNotFound' />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
