import { Grid } from '@mui/material';

import { MuiTypography } from '../../../../components/typography/typography';
import EmptyState from '../../../../assets/images/add-friend-empty-state.svg';

const text = {
  empty: 'هنوز دوستی در میزبان ندارید، دوستان خود را برای دورهمی دعوت کنید.',
};

export const SuggestionEmptyState = ({ open }) => {
  return (
    <div
      className='add-friend-empty-state'
      style={{
        marginTop: open === 'block' ? '-120px' : 0,
      }}
    >
      <Grid container columns={{ xs: 4 }} className='grid'>
        <Grid item xs={2.5}>
          <img src={EmptyState} alt='empty-state' />
        </Grid>
        <MuiTypography value='captionLg'>{text.empty}</MuiTypography>
      </Grid>
    </div>
  );
};
