import { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { userDataTransform } from './auth.service';
import {
  getAccessToken,
  isTokenExpired,
  removeCookies,
  removeUser,
  request,
} from '../../utils/fetch-request';
import { urlConfig } from '../../utils/env';
import { isObject } from '../../utils/utility';

export const AuthContext = createContext();

const default_location = {
  lat: '29.622447',
  lng: '52.523437',
};

export const AuthProvider = ({ children }) => {
  const refreshToken = Cookies.get('_mzb_token');
  const [user, setUser] = useState(null);
  const [mobilePhone, setMobilePhone] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [friends, setFriends] = useState([]);
  const [accessToken, setAccessToken] = useState('');
  const [isEnterMobilePhoneByUser, setIsEnterMobilePhoneByUser] =
    useState(false);

  useEffect(() => {
    refreshToken && getAccessToken(null, null, null, null, setAccessToken);
  }, [refreshToken]);

  const fetchingUserData = () => {
    fetch(urlConfig.user, 'get')
      .then((response) => {
        if (response && response.data && isObject(response.data)) {
          setUser(userDataTransform(response.data));
        }
      })
      .catch((e) => {
        removeUser(e);
      });
  };

  useEffect(() => {
    accessToken && fetchingUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const fetch = async (url, method, data, setIsLoading) => {
    const refresh = Cookies.get('_mzb_token');

    if (!refresh) {
      removeCookies();
      return;
    }

    if (accessToken && !isTokenExpired(accessToken)) {
      // Access token is valid
      return request(url, method, data, accessToken);
    }
    // Access token is expired or not available, request a new one
    return getAccessToken(url, method, data, setIsLoading, setAccessToken);
  };

  // const convertFriendsList = (data) => {
  //   let friendsArr = [];

  //   data.friends &&
  //     isArray(data.friends) &&
  //     data.friends.forEach((friend) => {
  //       friendsArr.push({
  //         username: friend.username,
  //         uid: friend.uid,
  //         phoneNumber: friend.phonenumber,
  //         avatar: friend.avatar,
  //       });
  //     });
  //   setFriends(friendsArr);
  // };

  // const getFriends = (user) => {
  //       request(`${urlConfig.friends}/${user.uid}`, response, 'get', {
  //         users_uuids: [user.uid],
  //       })
  //         .then((res) => {
  //           res.data && convertFriendsList(res.data);
  //         })
  //         .catch(console.log);
  // };

  // useEffect(() => {
  //   user && user.uid && getFriends(user);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        friends,
        isAuthenticated: !!refreshToken,
        isEnterMobilePhoneByUser,
        mobilePhone,
        default_location,
        accessToken,
        fetch,
        setUser,
        setMobilePhone,
        setIsEnterMobilePhoneByUser,
        setAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
