import React from 'react';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { text } from '../../../texts/main-page';
import { MuiButton } from '../../../components/button';
import { ReactComponent as Back } from '../../../assets/icon/back.svg';
import { MuiTypography } from '../../../components/typography/typography';

const navItems = [
  {
    text: text.introduceInMizban,
    url: '/',
    className: 'business-account-btn',
  },
  {
    text: text.support,
    url: '/',
    className: 'nav-link',
  },
  {
    text: text.aboutUs,
    url: '/',
    className: 'nav-link',
  },
];

const DrawerAppBar = (props) => {
  const { window, mobileOpen, handleDrawerToggle } = props;
  const navigate = useNavigate();

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Box className='drawer' sx={{ textAlign: 'center' }}>
      <IconButton onClick={handleDrawerToggle}>
        <Back />
      </IconButton>

      <MuiButton
        onClick={() => navigate('login')}
        variant='contained'
        className='login-btn'
        fullWidth
      >
        <MuiTypography value='bodyMd'>{text.login}</MuiTypography>
      </MuiButton>

      <List>
        {navItems.map((item) => (
          <React.Fragment key={item.text}>
            <Divider className='divider' />

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => navigate(item.url)}
                className={`${item.className} list-item-button`}
              >
                <MuiTypography value='bodyMd'>{item.text}</MuiTypography>
              </ListItemButton>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <Box component='nav'>
      <Drawer
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default DrawerAppBar;
