import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { AuthContext } from '../../services/authentication/auth.context';
import AppContainer from '../app-container';

export const RegisterLayout = () => {
  const { user, isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated && user && user.username) {
    return <Navigate to='/meetings' replace />;
  }

  if (!isAuthenticated) {
    return <Navigate to='/' replace />;
  }

  return (
    <AppContainer>
      <Outlet />
    </AppContainer>
  );
};
