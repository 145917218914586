import { useNavigate } from 'react-router-dom';

import './style.scss';
import ChatRoomSkeleton from './chat-room-skeleton';
import {
  CircleSkeleton,
  RectangleSkeleton,
} from '../../../../components/skeleton/skeleton';
import { ReactComponent as BackIcon } from '../../../../assets/icon/back.svg';

const votingItem = (
  <div className='voting-item'>
    <CircleSkeleton className='voting-photo' />
    <div>
      <RectangleSkeleton className='voting-name' />
      <RectangleSkeleton className='voting-location' />
    </div>
  </div>
);

const votingButton = <RectangleSkeleton className='voting-button' />;

export const VotingSkeleton = () => {
  const navigate = useNavigate();

  return (
    <div className='voting-skeleton-container'>
      <div className='voting-button-box'>
        <BackIcon
          onClick={() => navigate('/meetings', { replace: true })}
          cursor={'pointer'}
          id='back'
        />
        {votingButton}
        {/* {votingButton} */}
      </div>

      <div className='voting-items-container'>
        {votingItem}
        {votingItem}
        {votingItem}
        {votingItem}
      </div>

      <ChatRoomSkeleton />
    </div>
  );
};
