import { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';

import './index.scss';
import { ReactComponent as BackIcon } from '../../../../assets/icon/back.svg';
import { AuthContext } from '../../../../services/authentication/auth.context';
import { AlertDialog } from './alert-dialog';
import { MuiButton } from '../../../../components/button';
import RejectMeetingDialog from './reject-meeting-dialg';
import { actions } from '../../../../texts/voting';

const Actions = ({ meeting, places, alert }) => {
  const navigate = useNavigate();
  const { addPlace, endOfVoting, rejectMeeting } = actions;

  const [isHost, setIsHost] = useState(false);
  const [open, setOpen] = useState(false);
  const [isEqual, setIsEqual] = useState(false);
  const [equalVote, setEqualVote] = useState([]);
  const [mostVoteObj, setMostVoteObj] = useState({});
  const [rejectMeetingLoading, setRejectMeetingLoading] = useState(false);
  const [openRejectMeetingDialog, setOpenRejectMeetingDialog] = useState(false);

  const { user } = useContext(AuthContext);

  const { id } = useParams();

  const { members } = meeting || '';

  useEffect(() => {
    const hostUid = members?.find((member) => member.status === 0)?.member_uuid;

    if (hostUid && user?.uid === hostUid) {
      setIsHost(true);
    } else {
      setIsHost(false);
    }
  }, [members, user]);

  const checkNumberOfVote = () => {
    let maxVote = 1;
    let maxObj = null;

    if (isHost) {
      places.length > 0 &&
        places.forEach((place) => {
          if (place.vote >= maxVote) {
            maxVote = place.vote;
            maxObj = place;
          }
        });

      const equalVoteArr =
        places.length > 0 && maxObj
          ? places.filter((place) => place.vote === maxObj.vote)
          : [];

      if (equalVoteArr.length > 1) {
        setOpen(true);
        setIsEqual(true);
        setEqualVote(equalVoteArr);
      } else if (maxObj) {
        setOpen(true);
        setIsEqual(false);
        setMostVoteObj(maxObj);
      } else {
        setOpen(true);
        setIsEqual(false);
        setMostVoteObj(null);
      }
    }
  };

  const handleClick = () => {
    // reject meeting
    if (!isHost) {
      setOpenRejectMeetingDialog(true);
    }
  };

  return (
    <div className='voting-actions' style={{ marginTop: alert ? 80 : 0 }}>
      {openRejectMeetingDialog ? (
        <RejectMeetingDialog
          id={id}
          meeting={meeting}
          isLoading={rejectMeetingLoading}
          setIsLoading={setRejectMeetingLoading}
          open={openRejectMeetingDialog}
          setOpen={setOpenRejectMeetingDialog}
        />
      ) : null}

      <div>
        <BackIcon
          onClick={() => navigate('/meetings', { replace: true })}
          cursor={'pointer'}
          id='back'
        />
      </div>

      <div className='actions'>
        {!isHost ? (
          <MuiButton
            onClick={handleClick}
            loading={isHost ? false : rejectMeetingLoading}
            disabled={isHost ? true : false}
            variant='text'
          >
            {isHost ? addPlace : rejectMeeting}
          </MuiButton>
        ) : null}

        {user ? (
          <>
            {isHost ? (
              <MuiButton
                onClick={checkNumberOfVote}
                variant='contained'
                className='end-meeting-btn'
                disabled={isHost ? false : true} //rejectMeetingLoading
              >
                {isHost ? endOfVoting : addPlace}
              </MuiButton>
            ) : null}
          </>
        ) : (
          <Skeleton variant='rounded' width={128} height={40} />
        )}
      </div>

      <AlertDialog
        open={open}
        setOpen={setOpen}
        id={id}
        isEqual={isEqual}
        equalVote={equalVote}
        mostVoteObj={mostVoteObj}
      />
    </div>
  );
};

export default Actions;
